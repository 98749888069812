/* You can add global styles to this file, and also import other style files */

/* Provide sufficient contrast against white background */
@import './assets/css/Artwork360.css';
@import './assets/css/box_styles.css';
@import './assets/css/Artwork_icons.css';
@import '../src/assets/css/fonts/font/bootstrap-icons.css'

@font-face {
  font-family: 'Poppins';
  src: url('assets/css/fonts/Poppins-Light.ttf');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}


@font-face {
  font-family: 'Poppins';
  src: url('assets/css/fonts/Poppins-Regular.ttf');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('assets/css/fonts/Poppins-Medium.ttf');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}


@font-face {
  font-family: 'Poppins';
  src: url('assets/css/fonts/Poppins-SemiBold.ttf');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

 :root {
      --normal-font-size: 1rem;
    
    }

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

body {
  background: #EEF0F8;
  display: flex;
  position: fixed;
  font-family: Poppins,'sans-serif' !important;
 -webkit-font-smoothing: antialiased;
 -moz-osx-font-smoothing: grayscale;
 font-size: var(--normal-font-size);
}
.bg-light{
    background:none !important;
}

.no_internet {
  display: block;
  margin: 0 auto;
  width: 100%;
  text-align: center;
}

.no_internet p{
   width: 55%;
   margin: 0 auto;
   font-size: 0.9rem;
   color: #484848;
 }

body, html {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  color: #1B232C;
  font-family: Poppins,'sans-serif' !important;
  color: #212529;
  margin: 0;
  padding: 0;
  font-size: 0.75rem !important;
  /*font-weight: 400;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
}
.artwork-logo {
  width: 80%;
  margin-left: 5px;
  image-rendering: smooth;
}
input::placeholder {
  color: #b5b5c3 !important;
  opacity: 1; /* Firefox */
}

input:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #b5b5c3 !important;
}

input::-ms-input-placeholder { /* Microsoft Edge */
  color: #b5b5c3 !important;
}





textarea::placeholder {
  color: #b5b5c3 !important;
  opacity: 1; /* Firefox */
}

textarea:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #b5b5c3 !important;
}

textarea::-ms-input-placeholder { /* Microsoft Edge */
  color: #b5b5c3 !important;
}




a {
  cursor: pointer;
  color: #1976d2;
}

.k-card {
  border-radius: 0;
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
}

code {
  color: #e01a76;
}

.custm-form-control:focus {
  color: #3f4254;
  background-color: #fff;
  border-color: #69b3ff;
  outline: 0;
}

.custm-form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 1.3rem + 2px);
  padding: .65rem 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #3f4254;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e4e6ef;
  border-radius: .42rem;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
  transition: border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
}

.k-grid-header .k-i-sort-asc-sm, .k-grid-header .k-i-sort-desc-sm, .k-grid-header .k-sort-order {
  color: #187de4 !important;
}

.kendo-custom-table table {
  width: 100% !important;
  max-width: 100% !important;
  font-weight: 400;
}

.k-column-title {
  font-size: 12px !important;
  font-weight: 400 !important;
}

.kendo-custom-table table th,
.kendo-custom-table table td {
  padding: .4rem .5rem !important;
  vertical-align: middle !important;
  border-bottom: 1px solid #e3ebf3 !important;
  font-size: 12px;
}

.kendo-custom-table .k-grid-content table tbody tr:hover {
  background: #d0dafd !important;
  border: 1px solid #fff !important;
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%) !important;
}

.k-pager-numbers .k-link.k-state-selected {
  color: #fff !important;
  background-color: #009ef7;
}

.kendo-custom-table .k-grid-header {
  padding-right: 0 !important;
  background-color: transparent !important;
}

.k-cell-inner > .k-link {
  padding: 12px 12px !important;
}

.kendo-custom-table .k-grid-header .k-grid-header-wrap {
  background-color: transparent !important;
}

  .kendo-custom-table .k-grid-header .k-grid-header-wrap table thead th {
    vertical-align: bottom !important;
    /*border-bottom: 1px solid #e9ecef !important;*/
    text-align: left !important;
    font-weight: 600 !important;
    /*color: #555 !important;
       background-color: #fff !important;*/
    padding: .6rem .8rem !important;
  }

.kendo-custom-table .k-grid-header th {
  vertical-align: bottom !important;
  /*border-bottom: 1px solid #e9ecef !important;*/
  text-align: left !important;
  font-weight: 600 !important;
  /*color: #555 !important;
     background-color: #fff !important;*/
  padding: .6rem .8rem !important;
}

.kendo-custom-table .k-grid-header table thead th,
.kendo-custom-table .k-grid-header table thead td {
  /*border-bottom: 2px solid #e3ebf3 !important;*/
}

.kendo-custom-table .k-grid-content table tbody tr {
  background: #fff !important;
}

.kendo-custom-table .k-grid-content table tbody td {
  font-weight: 400 !important;
  /*border-bottom-width: 0px !important;*/
  white-space: nowrap;
  padding: 7px !important;
}

.kendo-custom-table .k-grid-content table tbody tr:nth-of-type(odd) {
  /*background-color: rgba(245,247,250,.5) !important;*/
}

.kendo-custom-table .k-grid td.k-state-selected, .kendo-custom-table .k-grid tr.k-state-selected > td {
  border-color: #ffffff !important;
}

.kendo-custom-table .k-pager-wrap {
  padding: 5px;
}

  .kendo-custom-table .k-pager-wrap .k-pager-numbers .k-state-selected {
    margin-top: 0em;
    padding-top: 0em;
    min-width: 2.429em;
  }

  .kendo-custom-table .k-pager-wrap .k-pager-sizes {
    line-height: 32px;
  }

.kendo-custom-table button.k-button {
  background: none;
  border: none;
  color: #187de4;
  font-weight: 500;
  font-size: 11px;
}

.kendo-custom-table .k-pager-wrap .k-pager-sizes .k-dropdown {
  margin-top: 0px;
  width: 4.2em !important;
}

.kendo-custom-table .k-pager-wrap .k-dropdown-wrap.k-state-default {
  padding: 0px 0 !important;
  margin-top: -1px;
}

.kendo-custom-table .k-pager-wrap .k-select .k-icon {
  top: -4px;
}

.k-dialog-titlebar {
  /* background: #f2f2f2 !important;
  color: #1B232C !important;
  padding:5px !important; */
  background: #f5f5f5 !important;
  color: #1B232C !important;
  /* color: #5f6466 !important; */
  font-weight: 500;
  padding: 5px 0px 5px 10px !important;
}

.k-content.k-window-content {
  padding: 0px !important;
}

/*.k-button.k-primary{
  border-color: #0078d4;
  color: #ffffff;
  background-color: #0078d4;
  font-weight: 500;
}
*/

.btn.btn-light {
  font-weight: 500;
}

.k-grid-pager {
  font-weight: 500;
}

.searchviewbtn {
  display: flex;
  justify-content: right;
  margin-top: 5px;
  margin-right: 15px;
}

.btn-excel {
  border: 1px solid #ddd !important;
  cursor: pointer;
  padding: 6px 8px 6px 8px;
  line-height: 12px;
  box-shadow: none !important;
}
  .btn-excel i {
    font-size: 1.2rem;
  }

.btn.btn-pdf {
  border-color: #e4e7eb !important;
  color: #212529 !important;
  background-color: #e4e7eb !important;
  font-size: 1rem !important;
  cursor: pointer;
}

.right-sidedev {
  float: right;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.right {
  float: right !important;
}

.left {
  float: left;
}

.m-tb-5 {
  margin: 5px 0 5px 0px;
}

.m-tb-10 {
  margin: 10px 0 10px 0px;
}

.m-tb-15 {
  margin: 10px 0 10px 0px;
}

.m-tb-20 {
  margin: 10px 0 10px 0px;
}

.m-t-5 {
  margin-top: 5px;
}

.m-t-10 {
  margin-top: 10px;
}

.m-b-10 {
  margin-bottom: 10px;
}

.m-tb-10 {
  margin-top: 10px;
}

.m-tb-15 {
  margin-top: 15px;
}

.m-tb-20 {
  margin-top: 20px;
}

.m-l-5 {
  margin-left: 5px;
}

.m-b-10 {
  margin-bottom: 10px;
}

.m-b-30 {
  margin-bottom: 30px;
}

.m-b-5 {
  margin-bottom: 5px;
}



.margin-lr-0 {
  margin-left: 0;
  margin-right: 0;
}

.m-lr-15 {
  margin-left: 15px;
  margin-right: 15px;
}

.p-r-0 {
  padding-right: 0;
}

.p-l-0 {
  padding-left: 0;
}

.padding-lr-0 {
  padding-left: 0px;
  padding-right: 0px;
}

.padding-lr-5 {
  padding: 0 5px;
}

.padding-lr-10 {
  padding: 0 10px;
}

.margin-tb-15 {
  margin: 15px 0 15px;
}

.p-tb-15 {
  padding-top: 15px;
  padding-bottom: 15px;
}

.p-t-15 {
  padding-top: 15px;
}

.p-t-5 {
  padding-top: 5px;
}

.padding-top-20 {
  padding-top: 20px !important;
}

.padding-top-10 {
  padding-top: 10px;
}

.padding-bottom-10 {
  padding-bottom: 10px !important;
}

.padding-bottom-15 {
  padding-bottom: 15px !important;
}

.margin-bottom-20 {
  margin-bottom: 20px !important;
}

.margin-top-20 {
  margin-top: 20px;
}

.margin-top-30 {
  margin-top: 30px;
}


.margin-right-5 {
  margin-right: 5px;
}

.margin-right-10 {
  margin-right: 10px;
}

.margin-lr-15 {
  margin-left: 15px;
  margin-right: 15px;
}

.margin-left-15 {
  margin-left: 15px;
}

.margin-right-15 {
  margin-right: 15px;
}

.margin-top-15 {
  margin-top: 15px;
}

.margin-right-20 {
  margin-right: 20px;
}

.k-grid tbody tr:hover, .k-grid tbody tr.k-state-hover {
  background-color: #ffffff;
}

.actions-btn {
  color: #2196f3;
  margin-left: 5px;
  float: right;
  background: #f2f2f2 !important;
  border: 1px solid #eee !important;
  margin-top: 5px;
}

.group-heding {
  padding-bottom: 10px;
  font-weight: 500;
  margin-top: 15px;
  font-weight: 500;
  margin-top: 15px;
  font-size: 13px;
  color: #1B232C;
}

.sub-hedings {
  margin-top: 15px;
  color: #1B232C;
  padding-left: 10px;
}

.width-100per {
  width: 100%;
}

/*.k-dropdown, .k-dropdowntree {
  width: 100%;
}*/

.k-textarea > .k-input {
  width: 100%;
}

.k-textarea {
  width: 100%;
}

/*.k-button-primary, .k-button.k-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}*/
/*
  .k-button-primary:hover, .k-button.k-primary:hover, .k-button-primary.k-state-hover, .k-state-hover.k-button.k-primary {
    color: #fff;
    background-color: #0069d9 !important;
    border-color: #0062cc
  }*/

.form-card {
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 2px;
  padding: 10px 15px 0px 15px;
  box-shadow: 0px 0px 30px 0px rgb(82 63 105 / 5%);
}

  .form-card h6 {
    font-size: 1rem;
    margin-top: 0.5rem;
    color: #6f7070;
  }

.k-datepicker, .k-timepicker {
  width: 100%;
}

.kendo-custom-table.k-dropdown, .k-dropdowntree {
  width: 50%;
}

.search-bar {
  height: 40px !important;
  position: absolute;
  display: flex;
}

.view-btn {
  color: #333;
  width: 35px;
  background-color: #fff !important;
  border-color: rgb(128 137 151 / 22%) !important;
  margin: 2px;
  float: left;
  padding: 2px 5px 5px 5px;
  /* box-shadow: 0px 8px 15px rgb(0 0 0 / 10%);*/
  border-radius: 5px;
  border: 1px solid #f2f2f2;
  cursor: pointer;
  height: 35px;
}

  .view-btn i {
    /*padding: 5px;*/
    font-size: 15px;
    cursor: pointer;
  }

/*.top-card {
  background: #fff;
  padding: 10px;
  border-radius: 5px;
  margin-top: 15px;
  box-shadow: 0px 2px 5px rgb(56 92 235 / 12%), 10px 1px 19px rgb(211 231 255 / 0%);
  margin-bottom:10px;
}
*/
.k-dropdown .k-dropdown-wrap, .k-dropdowntree .k-dropdown-wrap {
  background: #fff;
}

.k-state-disabled [disabled], .k-state-disabled .k-state-disabled, .k-widget[disabled] [disabled], .k-widget[disabled] .k-state-disabled, .k-disabled [disabled], .k-disabled .k-state-disabled {
  background: #eeeff2;
  color: #212529;
  cursor: not-allowed;
}

.font-weight-500 {
  font-weight: 500;
}
/*::-webkit-scrollbar {
  width: 25px;
  height: 10px;
  cursor:pointer;
}*/

::-webkit-scrollbar-thumb {
  background: #ababab;
  border-radius: 5px;
  border: 2px solid transparent;
  background-clip: padding-box;
 
}

  /*::-webkit-scrollbar-thumb:hover {
    border: 0;
  }

::-webkit-scrollbar-track {
  background: transparent;
}
*/


.k-button:focus, .k-button.k-state-focus, .k-button.k-state-focused, .k-button-secondary:focus, .k-button-secondary.k-state-focus, .k-button-secondary.k-state-focused {
  box-shadow: none;
}

.loader {
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(255,255,255,0.5);
  z-index: 9999;
}


@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.spinner-border {
  display: none !important;
}

.loader::after {
  content: '';
  display: block;
  position: absolute;
  left: 48%;
  top: 40%;
  width: 40px;
  height: 40px;
  border-style: solid;
  border-color: #377bd0;
  border-top-color: transparent;
  border-width: 4px;
  border-radius: 50%;
  -webkit-animation: spin .8s linear infinite;
  animation: spin .8s linear infinite;
}

@-ms-viewport {
  width: device-width;
}

@media only screen and (max-width: 1536px) {
  body {
    overflow: hidden;
  }
}


:root {
  --header-height: 2.5rem;
  --nav-width: 55px;
  --first-color: #fff;
  --first-color-light: #AFA5D9;
  --white-color: #555;
  /* --normal-font-size: 1rem;*/
  --z-fixed: 100
}

*,
::before,
::after {
  box-sizing: border-box
}

/*body {
  position: relative;
  margin: var(--header-height) 0 0 0;
  padding: 0 1rem;
  font-family: var(--body-font);
  font-size: var(--normal-font-size);
  transition: .5s
}
*/
a {
  text-decoration: none
}

.header {
  width: 100%;
  position: relative;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
  /*background-color: #0078D4;*/
  background: #fff;
  z-index: 9; /*var(--z-fixed);*/
  transition: .5s;
  border-bottom: 1px solid #eef0f8;
  margin-bottom: 1px;
}

.header_pdf {
  border-bottom: 2px solid #eef0f8;
  padding: 15px 15px 5px;
  color: #707070;
}

.header.navbar-light .navbar-brand {
  color: #fff;
  font-size: 20px;
  text-transform: uppercase;
}

.navbar-brand {
  width: 20%;
}

.header_toggle {
  color: #fff;
  font-size: 1.5rem;
  cursor: pointer
}

.header.navbar-light .navbar-nav .nav-link {
  color: rgb(37 38 41 / 71%);
  margin: 2px 12px 0px 12px;
  cursor: pointer;
  font-family: Poppins,'sans-serif' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 500;
}

  .header.navbar-light .navbar-nav .nav-link:hover {
    color: #009ef7 !important;
    text-decoration: none;
  }

.nav-itemcolor {
  color: rgb(37 38 41 / 71%);
  text-decoration: none;
  font-weight: 500;
  cursor: pointer;
}

  .nav-itemcolor:hover {
    color: #009ef7;
    text-decoration: none;
  }

.header ul.navbar-nav.mr-auto {
  padding-left: 50px;
}

.nav-link i {
  padding-right: 5px;
  font-size: 15px;
}

.nav-link span {
  font-size: calc(0.55em + 1vmin);
 /* font-weight: 500;*/
}

.header_img {
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  border-radius: 50%;
  overflow: hidden
}

  .header_img img {
    width: 40px
  }

.nav-item dropdown {
  padding: 10px 0;
}

.height-90vh {
  height: 90vh;
  overflow: auto;
}

/* .dropdown-menu.login {
  margin-left: -45px; 
  margin-left: 0px;
} */

.dropdown-item {
  padding: 10px;
  cursor: pointer;
}

.btn-none {
  background: none !important;
  border: none;
}

.l-navbar {
  position: fixed;
  top: 0;
  left: -30%;
  width: var(--nav-width);
  height: 100vh;
  background-color: var(--first-color);
  padding: .5rem 1rem 0 0;
  transition: .5s;
  /* z-index: var(--z-fixed)*/
}

.nav {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden
}

/*.nav_logo,
.nav_link {
  display: grid;
  grid-template-columns: max-content max-content;
  align-items: center;
  column-gap: 1rem;
  padding: .5rem 0 .5rem 1.5rem
}*/

.nav_logo {
  margin-bottom: 1rem;
  text-decoration: none;
}

.nav_logo-icon {
  font-size: 1.25rem;
  color: var(--white-color)
}

.nav_logo-name {
  color: var(--white-color);
  font-weight: 700
}

/*.nav_link {
  position: relative;
  color: #555;
  margin-bottom: 1.5rem;
  transition: .3s;
  text-decoration: none !important;
  cursor: pointer;
}*/

/*  .nav_link:hover {
    color: #0056b3;
  }*/

.nav_icon {
  font-size: 1.25rem
}

.show {
  left: 0
}

.body-pd {
  padding-left: calc(var(--nav-width) + 1rem)
}

.nav-link.sidebarnav.active {
  /* border-bottom: 2px solid #fff;*/
  background: #f4f6fa;
  border-radius: 5px;
  color: #009ef7 !important;
}

/*.active::before {
    content: '';
    position: absolute;
    left: 0;
    width: 2px;
    height: 32px;
    background-color: #187de4
  }*/

.height-100 {
  height: 100vh
}

.nav_list {
  list-style-type: none;
  padding: 0px;
}

/*@media screen and (min-width: 768px) {
  body {
    margin: calc(var(--header-height) + 0rem) 0 0 0;*/
/* padding-left: calc(var(--nav-width) + 0rem)*/
/*padding-left: 40px;
     margin-top:20px;
  }

  .header {
    height: calc(var(--header-height) + 1rem);
    padding: 0 2rem 0 calc(var(--nav-width) + 2rem)
  }

  .header_img {
    width: 40px;
    height: 40px
  }

    .header_img img {
      width: 45px
    }

  .l-navbar {
    left: 0;
    padding: 1rem 1rem 0 0
  }

  .show {
    width: calc(var(--nav-width) + 165px)
  }

  .body-pd {
    padding-left: calc(var(--nav-width) + 170px)
  }
}*/
.k-button.btn-primary {
  background-color: #009ef7;
  color: #fff;
}

  .k-button.btn-primary:hover {
    background-color: #2884EF;
    color: #fff;
  }


.selection-project {
  color: #fff;
  margin-left: 90%;
  cursor: pointer;
  border: 1px solid #f2f2f2;
  padding: 5px 10px;
  border-radius: 5px;
  width: 35px;
}

.module-select {
  background-color: #f8f9fa !important;
  padding: 45px;
  width: 35%;
  float: left;
  margin-left: 50px;
  margin-top: 40px;
}

.Logo-text {
  font-weight: 500;
}

.margin-top-10 {
  margin-top: 10px !important;
}

.k-content.k-window-content.k-dialog-content {
  font-family: Poppins,'sans-serif' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 15px !important;
}

.k-dialog-content p {
  font-size: 1.05rem !important;
}

.form-group.k-animation-container.k-animation-container-show {
  margin-top: 300px !important;
}

.project-box-wrapper {
  padding: 8px;
  transition: 0.2s;
}

.project-boxes.jsListView .project-box {
  display: flex;
  border-radius: 10px;
  position: relative;
}

.k-animation-container.k-animation-container-show {
  margin-top: 8px !important;
  background-color: #fff !important;
  border: 2px solid #b5bfd9 !important;
  border-radius: 8px !important;
  display: flex !important;
  flex-direction: column !important;
  padding: 0.5rem !important;
  box-shadow: 0 3px 7px 0 #c8cfe1 !important;
}

.icon-bg {
  width: 42px;
  height: 42px;
  padding: 13px;
  flex-shrink: 0;
  margin-left: 10px;
  border-radius: 50%;
  overflow: visible;
  background-color: #eaeefc;
  color: #4d76fd;
}

.dropdown-submenu {
  position: relative;
}

  .dropdown-submenu > .dropdown-menu {
    top: 0;
    left: 100%;
    margin-top: -6px;
    margin-left: -1px;
    -webkit-border-radius: 0 6px 6px 6px;
    -moz-border-radius: 0 6px 6px;
    border-radius: 0 6px 6px 6px;
  }

.dropdown:hover > .dropdown-menu {
  display: block;
}

.dropdown-submenu:hover > .dropdown-menu {
  display: block;
}

.dropdown-submenu > a:after {
  display: block;
  content: " ";
  float: right;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
  border-width: 5px 0 5px 5px;
  border-left-color: #ccc;
  margin-top: 5px;
  margin-right: -10px;
}

.dropdown-submenu:hover > a:after {
  border-left-color: #fff;
}

.tasks-view {
  background: #fff;
  height: 90vh;
  overflow: auto;
}

.projectlist-rightdiv {
  display: block;
  position: relative;
  height: calc(100vh - 100px);
  overflow: auto;
  padding-left: 15px;
  padding-right: 15px;
  top: 40px;
}

.projecttask-rightdiv {
  display: block;
  position: relative;
  overflow: auto;
  padding-left: 0px;
  padding-right: 0px;
  overflow-x: hidden;
}

.project-rightdiv {
  display: block;
  position: relative;
  height: calc(100vh - 60px);
  overflow: auto;
  padding-left: 15px;
  padding-right: 15px;
}

.takslist-topstrip {
  border-bottom: 1px solid #fff;
  width: 100%;
  box-shadow: rgb(17 17 26 / 10%) 0px 1px 0px;
  background: rgb(244 246 250);
  z-index: 1;
  height: 40px;
}

h1, h2, h3, h4, h5, h6 {
  color: #1B232C;
  font-family: Poppins,'sans-serif' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.border-right {
  border-right: 1px solid #eee;
}

.border-bottom-eee {
  border-bottom: 1px solid #eee;
}

.report-gridview .k-textbox {
  height: 29px;
}

.k-dropdown .k-dropdown-wrap, .k-dropdowntree .k-dropdown-wrap {
  border: 1px solid #dbdbdb !important;
  height: 35px;
  padding: 2px !important;
}

.k-textbox {
  height: 35px;
  padding: 5px !important;
  border: 1px solid #dbdbdb !important;
}

.k-multiselect-wrap.k-floatwrap {
  max-height: 70px !important;
  overflow: auto;
}

.k-multiselect-wrap.k-floatwrap {
  
  border: 1px solid #dbdbdb !important;
  font-weight: 500;
  overflow-x: hidden;
  overflow-y: auto;
}

.k-picker-wrap {
  height: 35px !important;
  padding: 2px !important;
  border: 1px solid #dbdbdb !important;
}

.k-upload .k-dropzone {
  height: 35px !important;
  padding: 2px !important;
  border: 1px solid #eee !important;
}

.k-icon {
  color: #808997;
}


.taks-accordn {
  background: none;
  border: none;
  float: left;
}

.task-titleinfo {
  font-size: 12px;
  font-weight: 500;
  padding: 2px;
  display: block;
  cursor: pointer;
  margin-bottom: 3px !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 70%;
}

.dal-titleinfo {
  font-size: 12px;
  font-weight: 500;
  padding: 2px;
  display: block;
  
  cursor: pointer;
  margin-bottom: 1px !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top:14px;
}
.taskinfo-tile {
  float: left;
  
  color: #808997;
  padding-left: 5px;
  padding-top: 5px;
  margin-left:20px;
 
}
.taskinfo-tasklist {
  float: left;
  color: #808997;
  font-size: 10px !important;
  margin-top: 7px;
  font-family: Poppins,'sans-serif' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 0px !important;
  letter-spacing: 1px;
  padding-left: 5px;
  padding-right: 5px;
  -webkit-font-smoothing: antialiased;
}

.select-data {
  background: #fff;
  padding: 50px;
  margin-top: 15%;
  width: 50%;
  margin-left: 25%;
  font-weight: 500;
  text-align: center;
  background-size: auto;
}

.select-img {
  background-image: url(assets/Images/select.png);
  background-repeat: no-repeat;
  background-size: auto;
  background-size: auto;
  height: 80px;
  width: 100%;
  margin-left: 42%;
}

.task-infodetails {
  float: left;
  
  padding-left: 15px;
  
  color: #808997;
  font-size: 10px;
}

.task-infodetails-date {
  float: left;
  /* width: 25%;*/
  padding-left: 10px;
  margin-top: 6px;
  color: #808997;
  font-size: 10px;
}

.task-infodetails-status {
  float: left;
  /*width: 20%;*/
  color: #FFA800 !important;
  background-color: #FFF4DE !important;
  margin-top: 5px;
  border-radius: 5px;
  color: #f9a4a4;
  font-size: 10px;
  text-align: center;
}




.status-task {
  margin-left: 10px !important;
  padding-left: 5px !important;
  padding-top: 1px !important;
  /*font-size: 10px !important;*/
}
.top-strip {
  background: rgb(244 246 250);
  /*height: 40px;
   padding: 5px;*/
  float: right;
}

.top-bar {
  /*position: absolute;*/
  border-bottom: 1px solid #fff;
  width: 100%;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px;
  z-index: 7;
  margin-top: 2px;
}


.topsubmenu-button {
  z-index: 9;
  /*position: fixed;
  margin-top: -40px;
  width: 74%;*/
}

.sort {
  color: #808997;
  cursor: pointer;
  padding-left: 10px;
  float: left;
}

  .sort i {
    font-size: 20px;
  }

.export {
  font-size: 15px !important;
  padding-top: 13px;
}

.sub-menu-icons {
  background: none;
  border: none !important;
  color: #333;
  font-family: Poppins,'sans-serif' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400 !important;
}

  .sub-menu-icons:hover {
    background: #E8F1FB;
  }

  .sub-menu-icons i {
    color: #187de4 !important;
    font-size: 17px;
    /* margin-right: 5px;*/
    font-weight: 500;
  }

.projectsub-rightdiv {
  width: 73.5%;
  padding-right: 0px;
  /*display: flex;*/
  position: relative;
  /*height: 90vh;*/
  overflow: auto;
  /* margin-left: 10px; */
}

.project-info.active {
  background: aliceblue;
}

.task-headlabel {
  font-size: 12px;
  font-weight: 500;
  color: #1B232C;
  margin-left: 5px;
  word-break: break-all;
  display: inline-table;
  position: relative;
}

.info-card {
  background: #fff;
  height: auto;
  padding: 5px 5px 5px 5px;
  box-shadow: 0px 0px 30px 0px rgb(82 63 105 / 5%);
  margin-left: 12px;
  margin-right: 12px;
}

.sidenav-info {
  font-size: 12px;
  font-weight: 500;
  color: #1B232C;
  margin-left: 5px;
  text-align: inherit;
  overflow: auto;
  width: 100%;
}

.text-color {
  color: #808997;
}

.text-dark {
  background: none;
  border: none;
  /*color: #fff !important;*/
}

.icons-top {
  background: none;
  border: none;
  color: #5e6278 !important;
}

.icon-top i {
  font-size: 15px;
}

.sub-top-bar {
  position: relative;
  margin-top: 40px;
  background: none !important;
}

.task-heading {
  position: relative;
  display: flex;
  font-weight: 500;
  padding: 0 5px;
  font-size: 18px;
  transition: 0.5s;
}

.module-proj .task-heading {
  padding: 0 0 5px;
  /* border-bottom: 2px solid #e4e6e9; */
   margin-left: 4px;
   margin-top:5px;
}

.m-l-9 {
  margin-top: 9px;
}

.main-div {
  /*overflow: hidden;*/
  /* height: 87vh; */
  height: 94vh;
  /* top: 0px;*/
  /* position: absolute;*/
  width: 100%;
  /* margin: 0px 5px;  */
  margin: 0px;
}

.margin-left-25 {
  margin-left: 25%;
}

.form-group label {
  /* color: #808997 !important; */
  font-weight: 500;
  color: #464b4b !important;
  font-size: 12px !important;
  font-family: Poppins,'sans-serif' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

.k-input {
  font-size: 12px !important;
  font-weight: 400;
}

.k-dateinput-wrap input {
  text-transform: uppercase !important;
}

.no-datadiv {
  text-align: center;
  margin-top: 15px;
  /* background: #f1faff;
  padding: 20px; */
  margin-left: 15px;
  margin-right: 15px;
  border: 1px solid #eef0f8;
  border-radius: 5px;
  box-shadow: 0 0 20px 0 rgb(76 87 125 / 2%);
}

.right-task-infodetails-status {
  float: left;
  color: #FFA800 !important;
  background-color: #FFF4DE !important;
  margin-top: 5px;
  border-radius: 5px;
  font-size: 10px;
  text-align: center;
  font-weight: 400 !important;
  width: auto !important;
  padding: 3px;
}
/*.k-list .k-item.k-state-selected, .k-list-optionlabel.k-state-selected {
  color: #ffffff;
  background-color: #ffffff !important;
}*/
.k-list .k-item, .k-list-optionlabel {
  box-shadow: none !important;
  padding: 8px 8px !important;
}

.project-info.right-projectinfo:hover {
  background: #fff;
}

.project-info.right-projectinfo.accordn-infodata.accordiondata-border {
  border-bottom: 1px solid #ccc;
}

.accordn-infodata {
  padding-left: 5px;
  /* margin-top: 0px;*/
  border: 1px solid #eee;
  padding-top: 5px;
  background: #fff !important;
  height: 100%;
  overflow: auto;
  display: inline-grid;
  padding-right: 5px;
  width: 100%;
  margin-bottom: 5px;
  overflow-x:hidden;
  margin-top:15px;

}

.project-info.right-projectinfo span:hover {
  color: #187de4;
}

.profileuser-name.sidebarnav.active {
  border-bottom: 2px solid #fff;
}

.project-info.right-projectinfo:hover {
  background: #f3f6f9;
  color: #187de4;
  cursor: pointer;
}

.project-info.right-projectinfo svg:hover {
  background: #eee;
  color: #187de4;
  cursor: pointer;
}

.accordn-infodata:hover {
  background: #fff;
  color: #1B232C;
  cursor: pointer;
}

.accordn-infodata div label:hover {
  color: #187de4;
  cursor: pointer;
}

.top-submenu-icons {
  background: none;
  float: left;
}
.top-submenu16 {
  float: right !important;
  position:relative;
}

.top-iconsalignment {
  margin-left: 24%;
  /*padding-top: 5px;*/
  /*float:right;*/
  padding-left: 0px !important;
}

.submenu-name {
  padding: 7px 0px 3px 0px;
  /*font-weight: 500;*/
  font-size: 13px;
  color: #5e6278;
  font-family: Poppins,'sans-serif' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  cursor: pointer;
}

.profile-drop i {
  font-size: 22px;
}

.profileuser-name {
  /* padding-left: 5px; */
  padding: 0 5px;
  text-transform: capitalize;
  /*font-size: 1.2em;*/
  font-size: calc(0.55em + 1vmin);
  /* font-weight: 500;*/
}

.newuser-profile {
  padding: 0 5px;
  text-transform: capitalize;
  font-size: calc(0.55em + 1vmin);
  padding-left: 0px !important;
  padding-bottom: 0px;
  margin-bottom: 0px;
  color: #494a4b !important;
}

.myprofile-btn {
  text-align: center;
  text-decoration: none;
  font-size: 12px;
  font-weight: 500;
  background: #009ef7;
  color: #fff;
  margin-bottom: 15px;
  margin-top: 5px;
  border-radius: 4px;
  padding: 4px 5px;
  cursor: pointer;
  width: 90% !important;
}

.project-rightdiv.task-heading.top-strip {
  margin-bottom: 15px;
}

.Project-card {
  cursor: pointer;
  /* border-bottom: 1px solid #eff2f5;*/
  background: #fff;
  border: 1px solid #ebedf5 !important;
  margin-left: 2px;
  margin-right: 2px;
  margin-top: 1px;
  border-radius: 2px;
}

.tasklist-tiles {
  border: 1px solid #d1d4d7a8;
  padding: 8px 0;
  margin-bottom: 15px;
  background: #fff;
  box-shadow: rgb(9 30 66 / 29%) 0px 4px 8px -2px, rgb(9 30 66 / 0%) 0px 0px 0px 1px;
}

  .tasklist-tiles:hover {
    /*background: #f4f6fa;*/
    background: aliceblue;
  }

.accordn-infodata label.task-titleinfo {
  color: #1B232C;
}

  .accordn-infodata label.task-titleinfo:hover {
    color: #187de4;
  }

.font-weight-600 {
  font-weight: 600;
}

.info-text {
  color: #808997;
  font-size: 12px;
}

.edit-btn {
  display: inline-block;
  font-weight: 500;
  line-height: 1.5;
  color: #0043ffc7;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: #f2f2f2 !important;
  border: 1px solid #eee !important;
  padding: .75rem 1.5rem;
  font-size: 1.1rem;
  border-radius: .475rem;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  width: 30px;
}

  .edit-btn:hover {
    color: #fff;
  }

.dropdown-toggle.active {
  border-bottom: 1px solid #fff;
}

.actions-btn {
  color: #007bff !important;
  background-color: #f2f2f2 !important;
  border-color: #eee !important;
}

.submenu-name:hover {
  color: #187de4;
}

.k-notification-group {
  /*top: 80px !important;*/
  z-index: 99999 !important;
  font-size: 12px !important;
  font-weight: 600 !important;
}

.custm-kendotable.k-toolbar.k-grid-toolbar {
  background: #fff;
}

.custm-kendotable.k-dropdown, .k-dropdowntree {
  width: auto !important;
}

.custm-kendotable.k-toolbar {
  background: #fff !important;
}

.action-btnicon {
  display: inline-block;
  font-weight: 500;
  line-height: 1.5;
  color: #181c32;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  /* background-color: transparent; */
  border: 1px solid #e4e8f7;
  padding: .75rem 1.5rem;
  font-size: 12px;
  border-radius: .475rem;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  background: #E4E8F7;
  color: #0069d9;
  width: 30px;
  height: 32px;
  padding: 5px;
  margin-left: 5px;
}

/*Kendo Css*/
.k-grid th, .k-grid td {
  padding: 0.80rem 0.75rem !important;
}

.k-grid-content.k-virtual-content {
  /* max-height: 52vh; */
  /*max-height: 65vh;
  overflow: auto;*/
}

.info-tooltipicon {
  /*float: right;*/
  color: rgb(24 125 228 / 72%) !important;
  cursor: pointer;
  text-decoration: none !important;
}

.left-project-div kendo-listview {
  transition: visibility 0.2s;
  height: 100%;
  overflow: auto;
  border: none !important;
  background: #eef0f8 !important;
}


.m-top-12 {
  margin-top: 12px;
}

.m-top-20 {
  margin-top: 20px !important;
}

.m-top-50 {
  margin-top: 50px !important;
}

.auditclass {
  width: 100%;
  border: solid 1px #f1f1f1;
}

  .auditclass td {
    width: 30% !important;
    word-break: break-all !important;
    border-right: 1px solid #eee !important;
    padding: 10px !important;
    line-height: 22px;
    text-align: justify;
  }

  .auditclass table tr:nth-child(odd) {
    background: rgba(245, 247, 250, 0.5) !important;
  }

  .auditclass table tr:nth-child(even) {
    background: white !important;
  }

  .auditclass tr:nth-child(odd) {
    background: rgba(245, 247, 250, 0.5) !important;
  }

.newtext-color {
  color: #0069d9;
}

.color-red {
  color: #f1416c !important;
}

.new-alert {
  position: relative;
  padding: 0px;
  font-size: 10px;
  margin-bottom: 0px;
  font-size: 0.9rem;
  font-weight: 500;
}

.project-infobar {
  width: 40%;
  background: #ffffff;
  float: right;
  padding: 25px;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid #f3f6f9;
  border-radius: .475rem;
  box-shadow: 0 0 20px 0 rgb(76 87 125 / 2%);
}

.k-progress-status {
  color: #001831 !important;
  font-weight: 500;
  font-size: 10px;
  z-index: 5 !important;
}

.k-listview {
  /* margin-top: 5px; */
  border: none !important;
}

.project-rightdiv.task-heading.top-strip {
  background: none !important;
}

.side-popup {
  position: absolute !important;
  height: 600px !important;
  float: right !important;
  margin-right: -998px !important;
  height: 100vh !important;
  width: 35% !important;
}
/*left right modal*/
.modal.left_modal, .modal.right_modal {
  position: fixed;
  /*z-index: 99999;*/
  z-index: 999;
  background: rgb(0 0 0 / 58%);
}

  .modal.left_modal .modal-dialog,
  .modal.right_modal .modal-dialog {
    position: fixed;
    margin: auto;
    width: 550px;
    max-width: 85%;
    height: 100%;
    -webkit-transform: translate3d(0%, 0, 0);
    -ms-transform: translate3d(0%, 0, 0);
    -o-transform: translate3d(0%, 0, 0);
    transform: translate3d(0%, 0, 0);
  }

.modal-dialog {
  /* max-width: 100%; */
  margin: 1.75rem auto;
}

@media (min-width: 576px) {
  .left_modal .modal-dialog {
    max-width: 100%;
  }

  .right_modal .modal-dialog {
    max-width: 100%;
  }
}

.modal.left_modal .modal-content,
.modal.right_modal .modal-content {
  /*overflow-y: auto;
    overflow-x: hidden;*/
  height: 100vh !important;
  /* top: 45px; */
  /*top: 25px;*/
}

.modal.left_modal .modal-body,
.modal.right_modal .modal-body {
  padding: 15px 15px 30px;
}

/*.modal.left_modal  {
    pointer-events: none;
    background: transparent;
}*/

.modal-backdrop {
  display: none;
}

/*Left*/
.modal.left_modal.fade .modal-dialog {
  left: -50%;
  -webkit-transition: opacity 0.3s linear, left 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, left 0.3s ease-out;
  -o-transition: opacity 0.3s linear, left 0.3s ease-out;
  transition: opacity 0.3s linear, left 0.3s ease-out;
}

.modal.left_modal.fade.show .modal-dialog {
  left: 0;
  box-shadow: 0px 0px 19px rgba(0,0,0,.5);
}

/*Right*/
.modal.right_modal.fade .modal-dialog {
  right: -50%;
  -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
  -o-transition: opacity 0.3s linear, right 0.3s ease-out;
  transition: opacity 0.3s linear, right 0.3s ease-out;
}



.modal.right_modal.fade.show .modal-dialog {
  right: 0;
  box-shadow: 0px 0px 19px rgba(0,0,0,.5);
}

/* ----- MODAL STYLE ----- */
.modal-content {
  border-radius: 0;
  border: none;
}



.modal-header.left_modal, .modal-header.right_modal {
  padding: 10px 15px;
  border-bottom-color: #EEEEEE;
  background-color: #FAFAFA;
}

.modal_outer .modal-body {
  /*height:90%;*/
  overflow-y: auto;
  overflow-x: hidden;
  height: 91vh;
}

.modal-header .close {
  margin: -1rem 0rem -1rem auto !important;
}

.right-progress {
  float: right;
  margin-top: 5px;
  margin-right: 20px;
}

.max-height-85vh {
  max-height: 85vh;
  overflow: auto;
}


.progress {
  margin: 0;
  overflow: visible;
  border-radius: 50px;
  background: none;
}

  .progress .progress-bar {
    border-radius: 50px;
  }

  .progress .progress-value {
    position: relative;
    left: -45px;
    top: 4px;
    font-size: 14px;
    font-weight: bold;
    color: #fff;
    letter-spacing: 2px;
  }

.progress-bar.active {
  /*  animation: reverse progress-bar-stripes 0.40s linear infinite, animate-positive 2s;*/
  background-color: #0078d436 !important;
}

@-webkit-keyframes animate-positive {
  0% {
    width: 0%;
  }
}

@keyframes animate-positive {
  0% {
    width: 0%;
  }
}

.disable-input {
  background: #e4e7eb;
}

.back-btn.k-button.k-primary {
  background: none;
  border: 1px solid #f2f2f2;
  color: #888;
  font-size: 12px;
  cursor: pointer;
  height: 29px;
}

.back-btn i {
  font-size: 14px;
  cursor: pointer;
}

.back-btn.k-button.k-primary:hover {
  background: none !important;
  color: #0078d4;
  cursor: pointer;
}

.back-btn.k-button-primary:focus, .k-button.k-primary:focus, .k-button-primary.k-state-focus, .k-state-focus.k-button.k-primary, .k-button-primary.k-state-focused, .k-state-focused.k-button.k-primary {
  box-shadow: none;
}

.dropdown-menu a {
  font-size: 12px;
  padding: 8px 12px;
}

#navbarSupportedContent .dropdown-item:active {
  background-color: #e8f1fb;
  color: #009ef7;
}

/*#navbarSupportedContent .dropdown-toggle::after {
  position: relative;
  top: 2px;
  left: -4px;
}
*/
.dropdown-menu a i {
  margin-right: 5px;
  /* font-size: 9px;*/
  padding: 7px;
  background: #f3f6f9;
  border-radius: 50px;
  color: #0078d4;
}

/*.dropdown-item.active, .dropdown-item i:active{
    color:#fff;
}
*/

.dropdown-menu {
  /* display: block;
  opacity: 0;
  visibility: hidden;
  transform: translateY(20%);
  transition: all .5s;*/
  padding: 0;
  border: 0;
  box-shadow: 0px 0px 5px #ddd;
}

.dropdown:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
  transform: translateY(0%);
}

.accordion-btn {
  text-decoration: none;
  font-weight: 500;
  box-shadow: none !important;
}

  .accordion-btn:hover {
    text-decoration: none;
  }

.back-btn.k-button.k-primary {
  background: none;
  border: none;
  /* color: #555; */
  color: #007bff;
  font-size: 13px;
  cursor: pointer;
  margin-bottom: -3px;
}

.back-btn i {
  font-size: 15px;
  cursor: pointer;
  padding-top: 2px;
}

.back-btn.k-button.k-primary:hover {
  background: none !important;
  color: #0078d4;
  cursor: pointer;
}

.back-btn.k-button-primary:focus, .k-button.k-primary:focus, .k-button-primary.k-state-focus, .k-state-focus.k-button.k-primary, .k-button-primary.k-state-focused, .k-state-focused.k-button.k-primary {
  box-shadow: none;
}

.btn-primary.focus, .btn-primary:focus {
  box-shadow: none !important;
}

.dropdown-menu.login {
  z-index: 105;
  border-radius: .475rem;
  background-color: #fff;
  box-shadow: 0 0 50px 0 rgb(82 63 105 / 18%);
  width: calc(100% - -16.5vw);
  left: auto;
  right: 0;
}

  .dropdown-menu.login:hover {
    border-radius: 0.475rem;
  }

  .dropdown-menu.login a.dropdown-item {
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 0;
    flex: 0 0 100%;
    /* padding: .65rem 1rem; */
    padding: .40rem 1rem;
    transition: none;
    outline: 0 !important;
    font-weight: 500;
    cursor: pointer;
    border-bottom: 1px solid #eef0f8;
  }

.treenode-active {
  background: aliceblue;
  color: #0078d4;
}

.view-btn.active {
  color: #0078d4;
}

.red-color {
  color: #f1416c !important;
}

.file-path {
  float: left;
  width: 25%;
  color: #808997 !important;
  padding-left: 5px;
  padding-top: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.file-ext {
  float: left;
  width: 10%;
  padding-left: 5px;
  padding-top: 5px;
  /* margin-right: 20%; */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 0px !important;
  color: #f1416c;
  font-weight: 500;
  text-transform: uppercase;
  margin-top:10px!important;

}

.file-size {
  float: left;
  width: 20%;
  color: #808997 !important;
  padding-left: 5px;
  padding-top: 5px;
  /* margin-right: 20%; */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top:10px !important;
}

.file-cycle {
  float: left;
  width: 10%;
  color: #808997 !important;
  padding-left: 5px;
  padding-top: 5px;
  /* margin-right: 20%; */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 10px !important;
}

.search-bar.k-textbox {
  height: 35px !important;
  padding: 0px 10px !important;
}

.alert-success {
  z-index: 99999 !important;
}

.k-panelbar > .k-item > .k-link.k-state-selected, .k-panelbar > .k-panelbar-header > .k-link.k-state-selected {
  color: #333a42;
  background-color: #fff !important;
  border: 1px solid #eee !important;
}

.k-panelbar > .k-item > .k-link:focus, .k-panelbar > .k-item > .k-link.k-state-focus, .k-panelbar > .k-item > .k-link.k-state-focused, .k-panelbar > .k-panelbar-header > .k-link:focus, .k-panelbar > .k-panelbar-header > .k-link.k-state-focus, .k-panelbar > .k-panelbar-header > .k-link.k-state-focused {
  box-shadow: none !important;
}

.k-panelbar > .k-item > .k-link, .k-panelbar > .k-panelbar-header > .k-link {
  border: 1px solid #eee !important;
}

  .k-panelbar > .k-item > .k-link:focus, .k-panelbar > .k-item > .k-link.k-state-focus, .k-panelbar > .k-item > .k-link.k-state-focused, .k-panelbar > .k-panelbar-header > .k-link:focus, .k-panelbar > .k-panelbar-header > .k-link.k-state-focus, .k-panelbar > .k-panelbar-header > .k-link.k-state-focused {
    box-shadow: none !important;
  }

.edit-divscrl {
  max-height: 65vh;
  overflow: auto;
  width: 100%;
  border: 1px dashed #cbcccd;
}

.k-multiselect .k-multiselect-wrap li.k-button, .k-dropdowntree .k-multiselect-wrap li.k-button {
  font-size: 10px !important;
  border-radius:25px;
}

.k-multiselect .k-multiselect-wrap .k-button .k-icon, .k-dropdowntree .k-multiselect-wrap .k-button .k-icon {
  font-size: 10px !important;
  padding-left: 0px !important;
  padding-top: 1px;
}

.k-icon.k-clear-value.k-i-close {
  font-size: 11px !important;
  padding-left: 15px !important;
}

.k-multiselect .k-multiselect-wrap .k-button .k-select, .k-dropdowntree .k-multiselect-wrap .k-button .k-select {
  margin-left: 0px !important;
}

.k-panelbar > .k-item > .k-link, .k-panelbar > .k-panelbar-header > .k-link {
  background: #fff !important;
}
.back-btn-new {
  position: absolute;
  margin-top: 8px !important;
  margin-left: 20px;
  cursor: pointer;
  z-index:99;
  
}


/*---Start Report Chart-Data srtyle--*/

.reports_chart .k-tabstrip-items {
  border-radius: 5px;
  background: #fff;
  margin-bottom: 6px;
  border: 1px solid #dddd !important;
}

.reports_chart .k-item {
  padding: 5px 8px;
  font-weight: 500;
  border: 0;
}

  .reports_chart .k-item:last-child {
    border-left: 1px solid #ddd !important;
  }

  .reports_chart .k-item:first-child {
    border-right: 1px solid #ddd !important;
  }

.reports_chart .k-content {
  padding: 16px !important;
  height: calc(100vh - 150px);
}

.reports_chart .k-chart, .k-stockchart {
  height: calc(100vh - 44.3vh); 
}

.rejection_chart87 .k-chart, .k-stockchart{
  height: calc(100vh - 60px)!important;
}

.task_report92 .k-chart, .k-stockchart {
  height: calc(100vh - 19.3vh) !important;
}
/*.reports_chart .k-textbox {
  height: 29px !important;
}
*/



.reports_chart .k-dropdown-wrap {
  height: 28px !important;
  padding: 0px !important;
}

/*---End Report Chart-Data srtyle--*/


kendo-tabstrip.main-tabs.k-tabstrip.k-floatwrap.k-tabstrip-top ul.k-reset.k-tabstrip-items {
  float: right !important;
  width: auto !important;
}

.k-tabstrip-items .k-item {
  color: #000000;
  font-weight: 500 !important;
}


kendo-tabstrip.dal-tabs.k-tabstrip-items-wrapper.k-reset k-tabstrip-items {
  background: #f2f2f2 !important;
}
/*kendo-tabstrip.dal-tabs.k-tabstrip.k-floatwrap.k-tabstrip-top.k-tabstrip-items-wrapper.k-hstack {

}*/

.k-tabstrip-items-wrapper.k-hstack {
  background: transparent;
  /*  padding: 3px;
  font-weight: 500;*/
}


.k-panelbar > .k-item > .k-link, .k-panelbar > .k-panelbar-header > .k-link {
  color: #212529;
}

.pdf-center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 40%;
}

.k-tabstrip-items .k-item.k-state-active, .k-tabstrip-items .k-item.k-state-selected {
  border: none !important;
  color: #0078d4;
  background: none !important;
}

  .k-tabstrip-items .k-item.k-state-active, .k-tabstrip-items .k-item.k-state-selected i {
    color: #0078d4;
  }

.dal-fileview {
  /* width: 20%;
  height: 20%;
  display: flex;
  border: 2px solid #fff;
  border-radius: 5px;*/
  margin-left: -15px;
  float: left;
}

.k-tabstrip-content:focus, .k-tabstrip-content.k-state-focused, .k-tabstrip > .k-content:focus, .k-tabstrip > .k-content.k-state-focused {
  outline-style: none !important;
}


/*login*/
.login-img {
  width: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  position: relative;
  z-index: 1;
  /* width: calc(100% - 560px);
  background-image: url(../src/assets/Images/Login_imgscreen.png);
  
  */
  /* height: 90vh;*/
  /* background-color: #f8f9fe; */
  background-color: #fff !important;
  border-right: 1px solid #eee;
  /*margin-left: -30px;*/
}

.login-container {
  /* height: 90vh; */
  height: auto;
}

.login_logo {
  width: 15%;
  margin-top: 25px;
  margin-left: 10px;
}

.login-wrap {
  margin: 0 20px;
  width: 100%;
  display: flex;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 30px 0px;
}

.login-form {
  background: #fcfcff;
  /* padding: 139px 100px; */
  /* padding: 9% 8% 10%; */
}

.login-hedding {
  /* color: #1B232C; */
  color: #0078D4;
  /* font-weight: 600;
  text-transform: uppercase; */
}

.login-formcontrol {
  height: 45px;
  background: #fff;
  position: relative;
  padding-left: 32px;
}

.login-button {
  /* height: 42px;
  float: right;
  margin-top: 20px;
  width: 25%;
  border-radius: 40px; */
  font-size: 1.2rem;
  border-radius: 50px;
  padding: 7px 25px 8px 25px;
}

.copy-right p {
  text-align: center;
  color: #808997;
  font-size: 0.88rem;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 20px;
}

.loginpage {
  position: relative;
  height: 320px;
  width: 360px;
  margin: auto;
  padding: 0 40px;
  margin-top: 180px;
}

.form-icon {
  position: relative;
  top: 32px;
  z-index: 9999;
  font-size: 15px;
  padding-left: 10px;
}

  .form-icon i {
    color: #898989;
  }

.logo {
  width: 100%;
  text-align: center;
  margin-top: -40px;
}

.forget-link {
  float: left;
  margin: 30px 0 0 0;
  font-size: 12px;
  color: #999;
  text-decoration: none;
}

  .forget-link:hover,
  .forget-link:focus,
  .forget-link:active {
    text-decoration: none;
    color: #666;
  }

.alert.alert-error {
  text-decoration: none;
  font-size: 11px;
  color: #f1416c !important;
  margin-bottom: 0px;
  position: absolute;
  z-index:99999999;
}

.info-login {
  /* font-size: 15px; */
  font-size: 1.12rem;
  margin-top: 10px;
  color: #808997;
}

.modal-confirm {
  color: #8e8e8e;
  width: 450px;
}

  .modal-confirm .modal-content {
    padding: 20px;
    border-radius: 5px;
    border: none;
  }

  .modal-confirm .modal-header {
    border-bottom: none;
    position: relative;
    justify-content: center;
    border-radius: 5px 5px 0 0;
  }

  .modal-confirm h4 {
    color: #545557;
    text-align: center;
    font-size: 30px;
    margin: 0 0 25px;
  }

  .modal-confirm .form-control, .modal-confirm .btn {
    min-height: 40px;
    border-radius: 3px;
  }

  .modal-confirm .close {
    background: #c0c3c8;
    position: absolute;
    top: 15px;
    right: 15px;
    color: #fff;
    text-shadow: none;
    opacity: 0.5;
    width: 30px;
    height: 30px;
    padding: 0;
    border-radius: 20px;
    font-size: 16px;
  }

    .modal-confirm .close:hover {
      opacity: 0.8;
    }

  .modal-confirm .icon-box {
    color: #f95858;
    display: inline-block;
    z-index: 9;
    text-align: center;
    position: relative;
    transform: scale(1.5);
  }

    .modal-confirm .icon-box i:first-child {
      font-size: 100px;
    }

    .modal-confirm .icon-box i:nth-child(2) {
      font-size: 138px;
      position: absolute;
      left: -19px;
      top: -23px;
      font-weight: bold;
      color: #fff;
    }

    .modal-confirm .icon-box i:last-child {
      font-size: 26px;
      position: absolute;
      left: 0;
      right: 0;
      margin: 0 auto;
      top: 44px;
    }

  .modal-confirm .btn {
    color: #fff;
    border-radius: 4px;
    background: #eeb711;
    text-decoration: none;
    transition: all 0.4s;
    line-height: normal;
    border-radius: 3px;
    margin: 30px 0 20px;
    padding: 6px 20px;
    min-width: 150px;
    border: none;
  }

    .modal-confirm .btn:hover, .modal-confirm .btn:focus {
      background: #eda645;
      outline: none;
    }

.trigger-btn {
  display: inline-block;
  margin: 100px auto;
}

.k-in.k-state-selected {
  cursor: pointer;
}

.k-listview-item {
  cursor: pointer;
}

.k-switch-handle {
  width: 12px !important;
  height: 12px !important;
  margin-top: -1px !important;
}

.k-switch-on .k-switch-handle {
  left: calc( 100% - 11px) !important;
}

.k-switch-container {
  width: 30px !important;
}

.task-icons i {
  font-size: 14px;
  color: #808997;
}

.notification-msg {
  padding: 15px;
  font-size: 15px;
  margin-right: 10px;
}

.file-collecn:hover {
  background: #E8F1FB;
  cursor: pointer;
}

.file-collecn {
  padding: 5px;
}

.k-label {
  color: #333;
}

.bg-fff {
  background: #fff !important;
}

.k-tabstrip > .k-content {
  border-color: #f3f6f9 !important;
  /* background-color: #f3f6f9 !important;*/
  /* padding-top:10px;
 padding-bottom:20px; */
  padding: 15px 15px !important;
  box-shadow: 0px 0px 30px 0px rgb(82 63 105 / 5%);
  border-radius: 2px;
}

.k-tabstrip-items {
  border-color: #f3f6f9 !important;
}

.Project-list {
  margin-top: 8px;
  margin-left: 5px;
  font-size: 14px;
}

.top-submenu-icons ul.k-widget.k-reset.k-header.k-menu.k-menu-horizontal {
  top: 0px;
  padding: 0px;
  margin: 0px;
  background: none;
  height: 35px;
}

.k-tabstrip-items .k-link {
  margin: 0px 0px 0px 0px !important;
  font-weight: 400;
  padding:0px !important;
}

.sub-menu-icons.new-submenu:hover {
  background: none !important;
}

.k-menu:not(.k-context-menu) > .k-item:focus, .k-menu:not(.k-context-menu) > .k-item.k-state-focused {
  box-shadow: none !important;
}

.k-menu-group .k-menu-link {
  padding: 5px !important;
}

.more-iconcs.icon-keyboard_control {
  font-size: 28px !important;
}

.top-submenu-icons .dropdwn-hide .k-widget .k-reset .k-header .k-menu .k-menu-horizontalk-item .k-menu-item .k-first .k-last {
  background: none !important;
}

.k-menu:not(.k-context-menu) {
  background: none !important;
}

.custom-css-class {
  text-align: center;
  margin-top: 25px;
}

.custom-css-class .k-dialog-content{
  line-height: 42px;
}

/*.k-link.k-menu-link {
  color: #fff !important;
  font-size: 1.2em !important;
  font-weight: 500 !important;
}*/
/*.k-icon{
    color:#fff !important;
}
*/
.task-.Completed {
  color: #1BC5BD !important;
  text-align: left;
  font-size: 10px;
  float: left;
  margin-top: 8px;
  font-weight: 500;
  padding-left: 20px;
  margin-right: 0%;
}

li.dropdown.show.nav-item Kendo-menu ul li span {
  font-size: 14px;
  font-weight: 400;
  color: #fff;
  margin-top: -1px;
}

.k-animation-container .k-animation-container-shown {
  left: 1292.72px !important;
  top: 84px !important;
}

.k-grid-header .k-header {
  font-weight: 500 !important;
}

.grid_download-btn {
  border: 1px solid #0078d4;
  border-radius: 5px;
  font-size: 9px;
  padding: 5px;
  font-weight: 500;
  text-align: center;
  margin-top: 0px;
  /*float: left;*/
  margin-left: 15px;
  color: #007bff !important;
}

.head-bg {
  font-weight: 500;
  background: #f3f6f9;
  padding: 15px 10px;
  height: 42px;
}

.pdf-css {
  color: #ff7878;
  font-size: 30px;
  float: left;
}

.file-name-css {
  color: #555 !important;
  font-weight: 500;
}

.grid-check {
  display: contents;
}

.k-treeview {
  font-family: Poppins,'sans-serif' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.top-0 {
  top: 0px !important;
}
/*.projectauto > div.ng-star-inserted:nth-child(-n+3) {
  width: 8%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
*/
.bg-f3f6f9 {
  background: #f3f6f9;
}

/*---Start review-tool-rightslide style--*/
.review-tool {
  border: 0;
}

  .review-tool iframe {
    height: calc(100vh - 45px);
  }

/*---End review-tool-rightslide style--*/
.end-selection {
  height: 35px !important;
  font-weight: 500;
}

.k-content.k-window-content {
  overflow-x: hidden;
}

.dalsearch44 .search_cancel{
  left: 52.2% !important;
}

.upload-dal {
  position: absolute;
  /* display: flex; */
  padding: 5px;
  background: #f3f6f9;
  top: -7px;
}


.img-logimg {
  /* width: calc(100% - 20px); */
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  /* position: relative;
  top: 55px;
  left: 25px; */
  width: 100%;
  height: calc(100vh - 117px);
}

.InProgress-OnTime, .In-Time {
  color: #0094FF;
}

.InProgress-Overdue, .Overdue {
  color: #f1416c;
}

.Completed-OnTime, .In-Time {
  color: #50cd89;
}

.Completed-Overdue, .Overdue {
  color: #f1416c;
}

/*.leftlistview-height {
   overflow: auto;
  max-height: 70vh;
  overflow: auto;
  width: 100%;
  overflow-y: hidden;
}*/
.deleteicon-color {
  color: #f1416c !important;
}

.project-rightdiv kendo-listview .list-viewcss {
  overflow: auto;
  max-height: 72vh !important;
}

.k-panelbar > .k-item > .k-link, .k-panelbar > .k-panelbar-header > .k-link {
  padding: 5px 5px 5px 10px;
  font-size: 12px;
  text-transform: capitalize;
}
/*.dal-middel{
    margin-top:40px;
}*/
.nav-btns {
  background: none !important;
  border: none !important
}

.module-noaccess {
  top: 50%;
  position: absolute;
  width: 100%;
  text-align: center;
  display: flow-root;
  font-size: 18px;
  font-weight: 500;
}

.listview-height {
  max-height: calc(100vh - 250px) !important;
  overflow: auto;
}

.display-none {
  display: none !important;
}

.display-block {
  display: block !important;
}

.disable-btn {
  cursor: not-allowed !important;
  /*background: rgb(242 242 242 / 38%);*/
  border: none;
  pointer-events: none;
  border-radius: 2px;
}

.dalleft-project-div {
  background: #fff;
  padding: 0px 0px 0px 10px;
  border-right: 1px solid #d1d4d7;
  height: 92vh;
  overflow: auto;
  top: 2px;
  box-shadow: rgb(0 0 0 / 15%) 1.95px 1.95px 2.6px;
  padding-left: 0px;
  overflow: visible;
}

.selectdal-radio-active {
  color: #007bff;
}

.card-header {
  padding: 0.35rem 0.85rem !important;
}

.btn-border {
  border: 1px solid #009ef7 !important;
}

.form-group kendo-dropdownlist {
  width: 100% !important;
}

.k-panelbar .k-group > .k-item > .k-link.k-state-selected, .k-panelbar .k-group > .k-panelbar-item > .k-link.k-state-selected, .k-panelbar .k-panelbar-group > .k-item > .k-link.k-state-selected, .k-panelbar .k-panelbar-group > .k-panelbar-item > .k-link.k-state-selected {
  color: #0078d4 !important;
  background-color: aliceblue !important;
}

.k-panelbar .k-group > .k-item > .k-link.k-state-selected, .k-panelbar .k-group > .k-panelbar-item > .k-link.k-state-selected, .k-panelbar .k-panelbar-group > .k-item > .k-link.k-state-selected, .k-panelbar .k-panelbar-group > .k-panelbar-item > .k-link.k-state-selected {
  color: #0078d4 !important;
  background-color: aliceblue !important;
}

.mondatory-color {
  color: #f1416c !important;
}

.k-autocomplete {
  width: 100% !important;
}

.chat-bg {
  padding: 5px;
  background: #f1faff;
  border-radius: 4px;
  margin-bottom: 10px !important;
  margin-right: 5px !important;
  border: 0.5px solid rgb(208 208 208 / 47%);
}

.height-72vh {
  height: 72vh;
  overflow: auto;
  overflow-x: hidden;
}

.display-messages {
  max-height: 45vh !important;
  overflow: auto;
  margin-bottom: 20px;
  padding: 15px 15px;
  margin-left: 20px;
  width: 48vw;
  border-radius: 5px;
}

.height-80vh {
  height: 80vh !important;
  overflow: auto;
}

.view-btn:hover {
  color: #0078d4;
}

.chat-boby {
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 0px;
}

.breadcrumb {
  z-index: 99;
  position: absolute;
  margin-left: 26.2%;
  font-weight: 500;
  margin-top: 42px;
  background: none;
  color: #7e7e7e !important;
}

  .breadcrumb i {
    padding-top: 2px;
    padding-right: 5px;
  }

.breadcrumbactive {
  color: rgb(0 120 212 / 66%);
  border-bottom: 1px solid;
}


.breadcrumb-tasklist {
  z-index: 99;
  position: absolute;
  margin-left: 26%;
  font-weight: 500;
  margin-top: 3px;
  background: none;
  color: #7e7e7e !important;
}

.left-project-div .k-in {
  padding: 0px 2px;
  width: 95%;
  text-overflow: ellipsis;
  white-space: break-spaces;
  color: #6b6c6c;
  font-weight: 400;
  text-align: left;
  display: flex;
  cursor:pointer;
}
  .left-project-div .k-in:hover {
    background: #e8f1fb;
    color: #009ef7;
    cursor: pointer;
  }

  .left-project-div .k-in.k-state-selected {
    border-color: #e8f1fb;
    color: #0078d4;
    background-color: #e8f1fb;
  }

    .left-project-div .k-in.k-state-selected.k-state-focused {
      box-shadow: none;
    }

.left-project-div .k-treeview .k-in.k-state-focused {
  box-shadow: none;
}

.module-proj .k-item {
  padding: 0;
  border-bottom: 1px solid #e4edf5;
  margin: 0 0px;
}

.k-collapse-prev {
  font-size: 18px !important;
}

.k-collapse-next {
  font-size: 18px !important;
}

.padding-tb {
  padding-top: 16px;
  padding-bottom: 16px;
}

.inputs_height .k-input {
  height: 32px !important;
}

.z-index-99 {
  /* z-index:99 !important; */
  z-index: 9 !important;
}

.height-panelbar.k-panelbar .k-group, .k-panelbar .k-panelbar-group {
  overflow: auto !important;
  max-height: 40vh !important;
  min-height: 15vh;
}


.btnexcel-pos {
  position: absolute;
  right: 5px;
}

.enable_filter {
  position: relative;
  display: block;
  font-weight: 500;
}

.audithist_filter {
  position: relative;
  right: 40px;
  display: block;
  font-weight: 500;
}

  .audithist_filter .k-checkbox {
    margin: 0 2px 2px 0;
  }

.dalenable_filter {
  position: relative;
  right: 0;
  display: block;
  font-weight: 500;
}

  .dalenable_filter .k-checkbox {
    margin: 0 2px 2px 0;
  }

.enable_filter .k-checkbox {
  margin: 0 2px 2px 0;
}

.enable_dfrm {
  display: block;
  font-weight: 500;
}

  .enable_dfrm .k-checkbox {
    margin: 0 2px 2px 0;
  }

/*.k-flat{
  padding: 5px;
  font-size: 1.2rem;
}
*/
.user_named {
  font-weight: 500 !important;
  text-transform: capitalize;
  color: #555;
}

.card_box {
  border: 0;
  box-shadow: 0 0rem 1rem rgb(0 0 0 / 8%) !important;
}

  .card_box .k-flat {
    padding: 5px;
    font-size: 1.2rem;
  }

.dynamic_frm .k-flat {
  padding: 5px;
  font-size: 1.2rem;
}

.dynamic_frm {
  border: 0;
}

  .dynamic_frm .k-textbox {
    height: 28px !important;
  }

  .dynamic_frm .k-dropdown-wrap {
    height: 28px !important;
    padding: 0px !important;
  }

.card_box .k-dropdown-wrap {
  height: 28px !important;
  padding: 0px !important;
}

.card_box .k-textbox {
  height: 28px !important;
}


.icon_fweight i {
  font-weight: 600;
}

.audit_pop .k-grid-content.k-virtual-content {
  max-height: 52vh !important;
  overflow: auto !important;
}

.multi_select {
  padding: 0 10px 0 0;
  color: #6b6c6c;
  font-weight: 500;
  line-height: 20px;
}

/*--Start Grid border style--*/
.k-grid-container td {
  border: 0;
}

.k-grid .k-alt:hover {
  background-color: rgba(0, 0, 0, 0.05);
}
/*--End Grid border style--*/

.pos_left {
  left: 25px !important;
}

.audit_sec .k-dateinput-wrap {
  border: 1px solid #dbdbdb !important;
}

.k-grid td, .k-grid .k-grid-content, .k-grid .k-grid-header-locked, .k-grid .k-grid-content-locked {
  border: none !important;
}

.tasklist-rightdiv {
  width: 57.5%;
  padding-right: 0px;
  position: relative;
  overflow: auto;
}

.options-card {
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  background: #fff;
}

.options-bypass {
  margin-top: 10px;
  height: 55vh;
  overflow: auto;
  padding: 0px 15px !important;
  background: #fff;
  border-radius: 5px;
  padding-bottom: 5px;
}

.top-newstripcard {
  /*border: 1px dashed #cbcccd;*/
  padding: 0px;
  position: relative;
  height: 50px;
}

.p-15 {
  padding: 15px !important;
}

.taks-tail {
  height: 50px !important;
  width: 120px !important;
  /* float: right !important;*/
  margin-right: 15px !important;
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

  .taks-tail.sub-menu-icons.due-today i {
    font-size: 25px;
    color: #ffc700 !important;
    padding-left: 10px !important;
  }

  .taks-tail.sub-menu-icons.up-coming i {
    font-size: 25px;
    color: #009ef7 !important;
    padding-left: 10px !important;
  }

  .taks-tail.sub-menu-icons.over-due i {
    font-size: 25px;
    color: #f1416c !important;
    padding-left: 10px !important;
  }

  .taks-tail.sub-menu-icons.completed i {
    font-size: 25px;
    color: #50cd89 !important;
    padding-left: 10px !important;
  }

  .taks-tail.sub-menu-icons.total i {
    font-size: 25px;
    color: #7239ea !important;
    padding-left: 10px !important;
  }

  .taks-tail.sub-menu-icons.due-today span.submenu-name {
    font-weight: 500 !important;
    color: #ffc700 !important;
    padding-right: 10px !important;
  }

  .taks-tail.sub-menu-icons.up-coming span.submenu-name {
    font-weight: 500 !important;
    color: #009ef7 !important;
    padding-right: 10px !important;
  }

  .taks-tail.sub-menu-icons.over-due span.submenu-name {
    font-weight: 500 !important;
    color: #f1416c !important;
    padding-right: 10px !important;
  }

  .taks-tail.sub-menu-icons.completed span.submenu-name {
    font-weight: 500 !important;
    color: #50cd89 !important;
    padding-right: 10px !important;
  }

  .taks-tail.sub-menu-icons.total span.submenu-name {
    font-weight: 500 !important;
    color: #7239ea !important;
    padding-right: 10px !important;
  }


.due-today {
  background-color: #fff8dd !important;
  color: #ffc700 !important;
  border: 1px solid !important;
}

.up-coming {
  background-color: #f1faff !important;
  color: #009ef7 !important;
  border: 1px solid !important;
}

.over-due {
  background-color: #fff5f8 !important;
  color: #f1416c !important;
  border: 1px solid !important;
}

.completed {
  background-color: #e8fff3 !important;
  color: #50cd89 !important;
  border: 1px solid !important;
}

.total {
  background-color: rgb(82 0 255 / 18%) !important;
  color: #7239ea !important;
  border: 1px solid !important;
}

.selected-language {
    margin-top: 3%;
    right: 0;
    margin-right: 14px;
    position: absolute;
}

  .selected-language kendo-dropdownlist span {
    background: #f8f9fe !important;
    color: #0062cc !important;
  }

    .selected-language kendo-dropdownlist span.k-dropdown .k-dropdown-wrap, .k-dropdowntree .k-dropdown-wrap {
      background: #f8f9fe !important;
    }

.taskClass {
  float: right !important;
  background: #fff !important;
  height: 60px;
  margin-bottom: 15px !important;
}

  .taskClass.top-submenu-icons.border-right-eee {
    border: none !important;
  }

  .taskClass.top-submenu-icons.border-right-eee {
    border-right: none !important;
  }

.text-ellipsis {
  white-space: nowrap;
  width: 94%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.search_resetbtn {
  justify-content: right;
  margin-right: 8%;
  margin-top: 10px;
}

.report_searchbtn {
  background: #009ef7;
  color: #ffff;
  font-weight: 500;
  padding: 4px 10px 5px;
}

  .report_searchbtn:hover {
    background: #2884EF;
    color: #fff;
  }

.report_resetbtn {
  font-weight: 500;
  margin-right: 6px;
  background: #009ef7;
  color: #fff;
}

.report_resetbtn:hover {
  background: #2884EF;
  color: #fff;
}

.serch_closebtn {
  position: relative;
  right: 1.7%;
  bottom: 59px;
  padding: 4px 10px 5px;
}

.report_datecreate {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
}

  .report_datecreate b {
    font-weight: 500;
    font-size: 1.03rem;
  }

.frm-goup {
  background: #fff;
  padding: 0 0 15px;
  margin-bottom: 15px;
}

  .frm-goup .k-multiselect {
    min-height: 35px;
  }

.Create-project .k-multiselect {
  height: 35px;
}

.save_search {
  margin-top: 28px;
  background: #0078d4 !important;
  font-weight: 500;
  color: #fff !important;
  opacity: 1 !important;
}

.save_searchbg {
  border: 1px dashed #f2f2f2;
  background: #f5f5f5;
  border-radius: 3px;
  padding: 10px;
}

.reportback_btn {
  background: transparent;
  color: #444;
  font-weight: 500;
  font-size: 1.2rem;
  border: 0;
  margin: 5px 0 5px -10px;
}

  .reportback_btn i {
    font-size: 15px;
    cursor: pointer;
  }

  .reportback_btn:hover {
    background: transparent;
  }

#deleteCustom {
  background: none !important;
}

/* .task_action .k-window-content{
  padding: 12px !important;
} */

.task_action .k-item {
  padding: 5px 10px;
  font-size: 1.05rem;
  border: 0;
  font-weight: 500;
  border-bottom: 2px solid #fff;
}

  .task_action .k-item:hover {
    border-bottom: 2px solid #fff;
  }


.task_action .k-tabstrip-items .k-item.k-state-active, .k-tabstrip-items .k-item.k-state-selected i {
  border-bottom: 2px solid #0078d4 !important;
}

.task_action .k-content {
  padding-bottom: 12px;
  background: #fff;
}

.task_action .k-window-content {
  background: #EEF0F8;
}

.task_action .k-tabstrip-items {
  background: #fff;
  border: 1px solid #eee;
}

.task_action h5 {
  font-size: 1.2rem;
  color: #5f6466;
}

.task_action .k-tabstrip {
  border: 1px solid #eee !important;
}



.zoom_in, .zoom_out {
  text-decoration: none !important;
  font-size: 1.3rem;
  border: 1px solid #eee;
  padding: 5px 10px 2px;
  position: relative;
  top: 0px;
  /*z-index: 9;*/
  /* color: #505050;*/
  width: 35px;
  /* border-radius: 2px;*/
}

.zoom_inoutbtn {
  margin-top: 23px;
}

.zoom_in.znone {
  display: none;
}

.zoom_out {
  display: none;
}

  .zoom_out.zdplay {
    display: block;
  }

.div-area-zoom.zoomdivone {
  position: fixed;
  width: 100%;
  top:-3px;
  left: 17px;
  padding: 15px;
  bottom: 5px;
  height: 103vh;
  z-index: 9999;
  background: #fff;
  /* box-shadow: 1px 1px 5px 4px #e8f1fb; */
  margin-left: -15px;
}

.div-area-zoom2.zoomdivone2 {
  position: fixed;
  width: 100%;
  top: 0px;
  left: 17px;
  padding: 15px;
  bottom: 5px;
  height:100vh;
  z-index: 999;
  background: #fff;
  /* box-shadow: 1px 1px 5px 4px #e8f1fb; */
  margin-left: -15px;
}


.carousel-container .carousel-content {
  height: 300px;
  overflow: hidden;
  background: rgba(0,0,0,0.9);
  position: absolute;
  top: 0;
  box-shadow: 0 5px 5px -5px #333;
  padding: 0;
  text-align: center;
  line-height: 296px;
}

  .carousel-container .carousel-content img {
    opacity: 0;
    padding: 0;
    width: auto;
    height: auto;
    max-height: 300px;
    max-width: 400px;
    vertical-align: middle;
    -webkit-animation-name: ziim;
    -webkit-animation-duration: 0.6s;
    animation-name: ziim;
    animation-duration: 0.6s;
  }

@-webkit-keyframes ziim {
  from {
    -webkit-transform: scale(1)
  }

  to {
    -webkit-transform: scale(0)
  }
}

@keyframes ziim {
  from {
    transform: scale(1)
  }

  to {
    transform: scale(0)
  }
}

.carousel-container .carousel-toggle:checked ~ .carousel-content, .carousel-container .carousel-toggle.active ~ .carousel-content {
  height: 100%;
  width: 100%;
}

  .carousel-container .carousel-toggle:checked ~ .carousel-content img, .carousel-container .carousel-toggle.active ~ .carousel-content img {
    opacity: 1;
  }

.carousel-fullscreen {
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 0 4px 4px 0;
  width: 27px;
  height: 27px;
  cursor: pointer;
  background: #000 url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABsAAAAbCAQAAAAn3TzeAAAACXBIWXMAAAsTAAALEwEAmpwYAAADGGlDQ1BQaG90b3Nob3AgSUNDIHByb2ZpbGUAAHjaY2BgnuDo4uTKJMDAUFBUUuQe5BgZERmlwH6egY2BmYGBgYGBITG5uMAxIMCHgYGBIS8/L5UBFTAyMHy7xsDIwMDAcFnX0cXJlYE0wJpcUFTCwMBwgIGBwSgltTiZgYHhCwMDQ3p5SUEJAwNjDAMDg0hSdkEJAwNjAQMDg0h2SJAzAwNjCwMDE09JakUJAwMDg3N+QWVRZnpGiYKhpaWlgmNKflKqQnBlcUlqbrGCZ15yflFBflFiSWoKAwMD1A4GBgYGXpf8EgX3xMw8BSMDVQYqg4jIKAUICxE+CDEESC4tKoMHJQODAIMCgwGDA0MAQyJDPcMChqMMbxjFGV0YSxlXMN5jEmMKYprAdIFZmDmSeSHzGxZLlg6WW6x6rK2s99gs2aaxfWMPZ9/NocTRxfGFM5HzApcj1xZuTe4FPFI8U3mFeCfxCfNN45fhXyygI7BD0FXwilCq0A/hXhEVkb2i4aJfxCaJG4lfkaiQlJM8JpUvLS19QqZMVl32llyfvIv8H4WtioVKekpvldeqFKiaqP5UO6jepRGqqaT5QeuA9iSdVF0rPUG9V/pHDBYY1hrFGNuayJsym740u2C+02KJ5QSrOutcmzjbQDtXe2sHY0cdJzVnJRcFV3k3BXdlD3VPXS8Tbxsfd99gvwT//ID6wIlBS4N3hVwMfRnOFCEXaRUVEV0RMzN2T9yDBLZE3aSw5IaUNak30zkyLDIzs+ZmX8xlz7PPryjYVPiuWLskq3RV2ZsK/cqSql01jLVedVPrHzbqNdU0n22VaytsP9op3VXUfbpXta+x/+5Em0mzJ/+dGj/t8AyNmf2zvs9JmHt6vvmCpYtEFrcu+bYsc/m9lSGrTq9xWbtvveWGbZtMNm/ZarJt+w6rnft3u+45uy9s/4ODOYd+Hmk/Jn58xUnrU+fOJJ/9dX7SRe1LR68kXv13fc5Nm1t379TfU75/4mHeY7En+59lvhB5efB1/lv5dxc+NH0y/fzq64Lv4T8Ffp360/rP8f9/AA0ADzT6lvFdAAAAIGNIUk0AAHolAACAgwAA+f8AAIDpAAB1MAAA6mAAADqYAAAXb5JfxUYAAAA/SURBVHjaYvjPQA5kGCHaUEApQylDKZrY4NEG5UG1YZUbetpKCcJBoG04R8DQyAGjJRfDf4b/DAAAAAD//wMAswEnryWu8FcAAAAASUVORK5CYII=') no-repeat center center;
}

.carouselFullScreen:checked, .carouselFullScreen.active {
  display: block;
  height: 100%;
}

  .carouselFullScreen:checked + .carousel-container, .carouselFullScreen.active + .carousel-container {
    margin: 0;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 9999;
  }

    .carouselFullScreen:checked + .carousel-container .carousel-toggle:checked ~ .carousel-content > li, .carouselFullScreen.active + .carousel-container .carousel-toggle:checked ~ .carousel-content > li {
      height: 100%;
      line-height: 99vh;
    }

    .carouselFullScreen:checked + .carousel-container .carousel-fullscreen, .carouselFullScreen.active + .carousel-container .carousel-fullscreen {
      width: 54px;
      height: 54px;
      background: #000 url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADYAAAA2CAQAAAAmTKJWAAAACXBIWXMAAAsTAAALEwEAmpwYAAADGGlDQ1BQaG90b3Nob3AgSUNDIHByb2ZpbGUAAHjaY2BgnuDo4uTKJMDAUFBUUuQe5BgZERmlwH6egY2BmYGBgYGBITG5uMAxIMCHgYGBIS8/L5UBFTAyMHy7xsDIwMDAcFnX0cXJlYE0wJpcUFTCwMBwgIGBwSgltTiZgYHhCwMDQ3p5SUEJAwNjDAMDg0hSdkEJAwNjAQMDg0h2SJAzAwNjCwMDE09JakUJAwMDg3N+QWVRZnpGiYKhpaWlgmNKflKqQnBlcUlqbrGCZ15yflFBflFiSWoKAwMD1A4GBgYGXpf8EgX3xMw8BSMDVQYqg4jIKAUICxE+CDEESC4tKoMHJQODAIMCgwGDA0MAQyJDPcMChqMMbxjFGV0YSxlXMN5jEmMKYprAdIFZmDmSeSHzGxZLlg6WW6x6rK2s99gs2aaxfWMPZ9/NocTRxfGFM5HzApcj1xZuTe4FPFI8U3mFeCfxCfNN45fhXyygI7BD0FXwilCq0A/hXhEVkb2i4aJfxCaJG4lfkaiQlJM8JpUvLS19QqZMVl32llyfvIv8H4WtioVKekpvldeqFKiaqP5UO6jepRGqqaT5QeuA9iSdVF0rPUG9V/pHDBYY1hrFGNuayJsym740u2C+02KJ5QSrOutcmzjbQDtXe2sHY0cdJzVnJRcFV3k3BXdlD3VPXS8Tbxsfd99gvwT//ID6wIlBS4N3hVwMfRnOFCEXaRUVEV0RMzN2T9yDBLZE3aSw5IaUNak30zkyLDIzs+ZmX8xlz7PPryjYVPiuWLskq3RV2ZsK/cqSql01jLVedVPrHzbqNdU0n22VaytsP9op3VXUfbpXta+x/+5Em0mzJ/+dGj/t8AyNmf2zvs9JmHt6vvmCpYtEFrcu+bYsc/m9lSGrTq9xWbtvveWGbZtMNm/ZarJt+w6rnft3u+45uy9s/4ODOYd+Hmk/Jn58xUnrU+fOJJ/9dX7SRe1LR68kXv13fc5Nm1t379TfU75/4mHeY7En+59lvhB5efB1/lv5dxc+NH0y/fzq64Lv4T8Ffp360/rP8f9/AA0ADzT6lvFdAAAAIGNIUk0AAHolAACAgwAA+f8AAIDpAAB1MAAA6mAAADqYAAAXb5JfxUYAAAD+SURBVHja7Jc9CoQwEIW9gkdJYyFoIeiFvIaH0VLB0sLGI3iE1DbhbRciGDJrltnFHV+Zpx9I3vwkSPiUCExgAvsH2A7tUY/Sukr0Xt9Ohx3wPQtq66qxeH0HHWa8H1nRWFeD1eszdJiOhulPwKi/8RZMY8PsqIOyLoXudLad3rsB29CicqSQWlcKdTprscXBJuTkHOWY4mAjMjIswxgHm1GRYRXmn4eZy1yF5ObO3ClXbq5CcnP3RrkaLnMVkpu7gQ4rLnMVkpu7Qprnk2GsF4T16rOGmrVcPbfqf6l5so4FrAMP6yjHOqQyjN+siwXrysS6DEqnFpjABEbRawACMWGE7CJdswAAAABJRU5ErkJggg==') no-repeat center center;
    }

    .carouselFullScreen:checked + .carousel-container img, .carouselFullScreen.active + .carousel-container img {
      width: auto;
      height: 100%;
      max-height: 100vh;
      max-width: 100vw;
      -webkit-animation-name: zoom;
      -webkit-animation-duration: 0.6s;
      animation-name: zoom;
      animation-duration: 0.6s;
    }

@-webkit-keyframes zoom {
  from {
    -webkit-transform: scale(0)
  }

  to {
    -webkit-transform: scale(1)
  }
}

@keyframes zoom {
  from {
    transform: scale(0)
  }

  to {
    transform: scale(1)
  }
}

input[type=checkbox].carouselFullScreen, input[type=checkbox].carousel-toggle {
  visibility: hidden;
}

#deleteCustom {
  background: none !important;
}

.half-circle {
  width: 200px;
  height: 100px;
  border-top-left-radius: 150px;
  border-top-right-radius: 150px;
  border: 50px solid blue;
  border-bottom: 0;
}

.task-btns {
  /* border: 1px solid #edeff7; */
  background-color: #f5f8fa;
  border-radius: 2px;
}

  .task-btns:hover {
    background: #e8f1fb;
  }

.task-btnshover:hover {
  color: #0078d4;
}

.btn-searchtop {
  border: none !important;
  /*height: 35px;
  width: 35px;*/
  border-radius: 5px;
  margin-top: 0px;
  box-shadow: none;
  /*font-size: 18px;*/
  margin-left: 5px;
  cursor: pointer;
  float: left;
  display: flex;
}

.btnserach {
  background: #f1faff;
  color: #009ef7;
}
  .btnserach:hover {
    background: #009ef7;
    color: #fff;
    cursor: pointer;
  }


.btnsancle {
  background: none !important;
  color: #f1416c !important;
  margin-top: 4px !important;
  cursor: pointer;
}

.task-btnsancle {
  margin-left: 3.3% !important
}

.btn-excel {
  background-color: #e8fff3;
  color: #50cd89;
}

  .btn-excel:hover {
    background: #50cd89;
    color: #FFFFFF;
  }

.btn-searchtop:hover {
  cursor: pointer;
  /* color: #187de4;*/
  background: #009ef7;
}
.project-advancesearch-btn {
  border: none !important;
  border-radius: 5px;
  margin-top: 0px;
  box-shadow: none;
  padding: 10px;
  margin-left: 5px;
  cursor: pointer;
  float: left;
  display: flex;
  height: 37px;
  margin-left: 15px;
}

.my_profile .k-item {
  padding: 5px 10px;
  font-size: 1.05rem;
  border: 0;
  font-weight: 500;
  border-bottom: 2px solid #fff;
}

  .my_profile .k-item:hover {
    border-bottom: 2px solid #fff;
  }


.my_profile .k-tabstrip-items .k-item.k-state-active, .k-tabstrip-items .k-item.k-state-selected i {
  border-bottom: 2px solid #0078d4 !important;
}

.my_profile .k-content {
  padding-bottom: 12px;
}

.my_profile .k-window-content {
  background: #EEF0F8;
}

.my_profile .k-tabstrip-items {
  background: #fff;
}

.primery-color {
  color: #0078d4 !important;
}

kendo-expansionpanel.search-accordn.k-expander-header {
  border-bottom: 1px solid #eef0f8 !important;
}

.secondary-color {
  color: #838691;
}

span.icon-pin1.secondary-color.rotate-pin {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  display: inline-block;
}

span.icon-pin1.primery-color {
  background: #eef0f8;
  border-radius: 30px;
  width: 20px;
  height: 20px;
  padding: 5px 5px;
}

.k-expander-header {
  color: #212529;
  /* background-color: transparent; */
  outline: none;
  background: #fff !important;
  height: 35px;
  border-bottom: 1px solid #eef0f8;
}

.k-expander-content {
  padding: 0rem 0rem !important;
  box-sizing: border-box;
}

.k-expander {
  border-color: none !important;
}

.pop-up-captr {
  float: left;
  margin-top: 52px;
  /* display: flex; */
  position: absolute;
  z-index: 999;
  margin-left: 33%;
  background: #0000006b;
  padding: 10px;
  border-radius: 5px;
}

.my_profile .k-tabstrip-items {
  background: #fff;
  border: 1px solid #eee !important;
}

.my_profile .k-tabstrip {
  border: 1px solid #eee !important;
}

.left-project-div .icon-keyboard_arrow_right {
  font-size: 1.3rem;
}

.tasklist-count {
  /* display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  color: #3f4254;
  background-color: #f5f8fa;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  border-radius: 5px;
  width: 40px;
  height: 41px;
  float: left;*/
  display: flex;
  justify-content: right;
  font-weight: 500;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  float: left;
  margin-left: 10px;
}

.countcss {
  font-size: 0.9rem;
  font-weight: 500;
 /* margin-left: 10px;*/
  cursor: pointer;
  /* padding-top: 3px;*/
  /* width: 25%;*/
  text-align: center;
}

.tasks-name {
  /*width: 50%;*/

  color: #a1a5b7;
  font-size: 0.9rem;
  padding-left: 5px;
  padding: 5px 8px 5px;
  margin-bottom:0px !important;
}

.taskcount-bg {
  border: 2px dashed #e4e6ef !important;
  border-radius: 5px;
  background: #ffffff;
  padding: 5px 5px 5px 5px;
  margin-left: 0px;
  width:100%;
}

.top-0 {
  margin-top: 0px !important;
}

.task-overdue {
  /* background-color: #fff5f8 !important;*/
  color: #f1416c !important;
  cursor: pointer;
}

.task-completed {
  /*background-color: #f1faff !important;*/
  color: #50cd89 !important;
  cursor: pointer;
}

.task-upcomin {
  /*background: #f8f5ff !important;*/
  color: #7239ea !important;
  cursor: pointer;
}

.task-duetoday {
  /* background-color: #fff8dd !important;*/
  color: #ffc700 !important;
  cursor: pointer;
}

.task-Total {
  /* background-color: #e8fff3 !important;*/
  color: #009ef7 !important;
  cursor: pointer;
}

.asset_library {
  width: 100%;
  display: flow-root;
}

.mypref {
  height: calc(100vh - 300px);
  overflow: auto;
  margin: 10px 0;
  border-bottom: 1px solid #eee;
}

  .mypref input {
    float: left;
    margin-top: 3px;
    margin-right: 6px;
  }

  .mypref label {
    color: #808997;
    font-size: 1.04rem;
    font-weight: 400;
    display: flex;
  }

.task-active {
  /* border-bottom: 2px solid;
  color: #0078d4;*/
  background: aliceblue;
  border-bottom: 1px solid;
  /*border-radius: 5px;*/
}

.tasklist-divs {
  /*  width: 18%;*/
  float: left;
  /* margin-bottom: 12px;*/
  /* border: 1px solid #eef0f8;*/
  border-radius: 5px;
  margin-right: 0px;
  /*height: 42px;*/
}

kendo-pager-info.k-pager-info.k-label {
  text-align: left !important;
  justify-content: left !important;
  display: block !important;
}

.chartcss {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  color: #3f4254;
  background-color: #f5f8fa;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  border-radius: 0.75rem;
  height: 52px;
  width: 50px;
  margin-right: 20px;
  cursor: pointer;
}

.popup-content {
  padding: 10px 20px !important;
  border: none !important;
}

.snapcaht-popup {
  padding: 10px;
  background: #0000007a;
  border-radius: 5px;
}

.chat-content.k-popup.content {
  padding: 0px;
  border: none;
}


.chat-content.k-popup.chat-content {
  padding: 0px !important;
  border: none !important;
  margin-left: -245px;
}

.task-divmargin {
  margin-top: 65px !important;
}

kendo-datapager.k-listview-pager.k-pager-wrap.k-pager.k-widget.k-pager-sm {
  border-top: 1px solid #e4e8ec !important;
}

kendo-datapager-info.k-pager-info.k-label {
  float: left !important;
  text-align: left !important;
  justify-content: left !important;
  display: block !important;
 font-weight:500;
}

kendo-datapager.k-listview-pager.k-pager-wrap.k-pager.k-widget.k-pager-md {
  border-top: 1px solid #e4e8ec !important;
}

.sideinfo-divheight {
  max-height: calc(100vh - 190px);
  overflow: auto;
}

/*.k-tabstrip > .k-content {
  padding: 15px 15px !important;
}*/

.k-progressbar-horizontal {
  width: 14rem !important;
}

.b-radius-5 {
  border-radius: 5px !important;
}

.approved-img {
  width: 35%;
  float: left;
  display: flex;
  opacity: 0.9;
  border-radius: 30px;
  border: 3px solid #cad3ef;
  border-radius: 30px;
  background: #fff;
}

.dal-downloadbtn {
  border: 2px solid #edeef7;
  padding: 0px;
  border-radius: 30px;
  /* color: #fff !important;*/
  float: right;
  text-decoration: none !important;
  width: 30px;
  cursor: pointer;
  height: 30px;
  background: #ffffff;
  position: relative;
  /* margin-top: -32px; */
  float: right;
}
/*.dal-downloadbtn:hover {
    color: #fff !important;
    background: #0078d4;
  }
*/

.chat-boby .k-upload-files {
  max-height: 16vh;
}

.add_reference .k-upload-files {
  max-height: 22vh;
}

.btn-pdfbtn {
  background: #fff5f8 !important;
  color: #f24c75 !important;
}

.k-upload .k-upload-files .k-file {
  padding: 5px 5px !important;
}

.bg_heading h5 {
  margin-top: 10px !important;
  padding: 5px 10px;
  font-size: 1.15rem;
  background: #eff0f1;
}

.suspend_proj .k-content {
  display: flex;
  justify-content: center;
  text-align: center;
  flex-direction: column-reverse;
}

.active_text {
  color: #0078d4;
  margin-top: 1px !important;
}

/*--Start Project Summary style--*/
.accord_bg .k-expander-header {
  background: #f2f2f2 !important;
  padding: 0 1rem !important;
  height: 30px !important;
}

.accord_bg .k-expander-title {
  color: #333a42 !important;
  text-transform: capitalize !important;
}

.k-expander.k-state-focus {
  box-shadow: none !important;
}

.k-expander {
  border-width: 1px !important;
}

/*--End Project Summary style--*/

/*--start breadcrumb style--*/
.bread_crumb {
  display: flex;
  margin: 5px 0px 5px;
}

  .bread_crumb li {
    list-style: none;
    font-size: 0.96rem;
    color: #9ca3af;
    font-weight: 500;
    letter-spacing: 0.4px;
  }

  .bread_crumb span {
    float: left;
    margin: 0 2px;
    word-break: break-word;
  }

  .bread_crumb i {
    font-size: 12px;
    padding-top: 1px;
    line-height: 12px;
  }
/*--End breadcrumb style--*/
.error-alert {
  color: #f1416c;
  font-size: 10px;
  font-weight: 400;
  z-index:999999999;
}

.dalgrid_download-btn {
  color: #007bff !important;
}

  .dalgrid_download-btn:hover {
    background: aliceblue;
  }

.ref-files {
  text-align: justify;
  border: 1px solid #edeff8;
  background: #eef0f869;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 25px;
  margin-right: 10px;
  width: 30vh;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  /* height:35vh; */
  height: auto;
}
.k-content {
  font-family: Poppins,'sans-serif' !important;
}
.k-panelbar > .k-item > .k-link, .k-panelbar > .k-panelbar-header > .k-link {
  font-family: Poppins,'sans-serif' !important;
}
.k-scheduler-table td, .k-scheduler-table th {
  font-family: Poppins,'sans-serif' !important;
}
.task-grid {
  max-height: calc(100vh - 325px);
  width: 100% !important;
}

.img-filename {
  text-align: justify;
  text-decoration: none;
  width: 90%;
  color: #007bff;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
}
  .img-filename:hover {
    color: #007bff;
    text-decoration: none;
  }
.forget-btn {
  background: none;
  border: none;
  color: #0062cc;
  font-weight: 400;
  text-decoration: underline;
}

.takslist-grid {
  /* max-height: calc(100vh - 135px);  */
  max-height: calc(100vh - 148px);
  width: 100% !important;
  display: block;
}

.reassign-usergrid {
  max-height: calc(100vh - 352px);
  width: 100% !important;
  overflow: auto;
}

.dal-gridview {
  max-height: calc(100vh - 232px);
  overflow: auto;
}

.report-gridview {
  max-height: calc(100vh - 235px);
  overflow: auto;
}

.completedreport-grideview {
  max-height: calc(100vh - 280px);
  overflow: auto;
}

.rejectionreason-reportgrid {
  max-height: calc(100vh - 280px);
  overflow: auto;
}

.project-listview {
  max-height: calc(100vh - 165px) !important;
  overflow: auto !important;
  width: 100%;
  /*  margin-top:5px;
  margin-bottom:5px;*/
}

.dal-newlistview {
  max-height: calc(100vh - 278px) !important;
  overflow: auto !important;
 
}

.btn-forget {
  background: none;
  border: none;
  color: #0078d4;
}

  .btn-forget:hover {
    background: none !important;
  }

.add-userheight {
  max-height: calc(100vh - 95px);
  overflow: auto;
}

.dallist-downloadbtn {
  padding: 5px;
  border-radius: 5px;
  color: #009ef7;
  float: right;
  text-decoration: none !important;
  background: #f1faff;
  margin-top: 25px;
  border: 1px solid #009ef7;
  padding: 5px 15px;
}
  .dallist-downloadbtn:hover {
    background: #009ef7;
    color:#fff;
  }
.ApprovedEffective {
  background: #50cd89;
  color: #fff;
  padding: 5px;
  border-radius: 5px;
  font-size: 9px;
  position: absolute;
  margin-top: 10px;
  margin-left:5px;
}
.Obsolete {
  background: #009ef7;
  color: #fff;
  padding: 5px;
  border-radius: 5px;
  font-size: 9px;
  position: absolute;
  margin-top: 10px;
  margin-left: 5px;
}
/*.k-pager-wrap .k-link {
  border-radius: 30px !important;
}*/
.listviewimg .dal-fileview img {
  height: calc(100vh - 90vh) !important;
}

.dal-newlistview.k-listview-item.Project-card {
  border-radius: 10px;
  margin-top: 5px;
  background: #ffffff;
}

.user-reportgrid {
  max-height: calc(100vh - 210px);
  overflow: auto;
}

.groupdesable.kendo-searchbar.k-searchbar {
  width: 270px !important;
}

/*.top-bread_crumb {
  margin-top: -34px;
  z-index: 999999999;
  position: fixed;
  width: 72%
}
*/
.audit-grid {
  max-height: calc(100vh - 245px);
  overflow: auto;
}

.ref-approve-img {
  float: left;
  /*position: absolute;*/
  position: relative;
  width: 32%;
  border: 3px solid #cad3ef;
  border-radius: 30px;
  background: #fff;
  opacity: 9;
  z-index: 999;
  top: 15px;
  margin-left: -10px;
}

.ref-pdffile app-dynamic-field-thumbnail div img {
  
  width: 85% !important;
  box-shadow: 0px 0px 20px 0px rgb(76 87 125 / 24%);
  border-radius: 5px;
  padding: 5px;
  -webkit-mask-image: radial-gradient(circle, black 0%, rgb(0 0 0 / 50%) 0%);
}

.ref-grid {
  max-height: calc(100vh - 295px) !important;
  overflow: auto;
}

.ref-gridheight {
  max-height: calc(100vh - 198px) !important;
  overflow: auto;
  margin-right: 0px !important;
}

.grid_total_height61 .ref-gridheight {
  max-height: calc(100vh - 278px) !important;
  overflow: auto;
  margin-right: 0px !important;
}

.Requests-listview {
  max-height: calc(100vh - 165px) !important;
  overflow: auto !important;
}

.complex-info {
  float: left;
  width: 20%;
  color: #808997;
  padding-left: 0px;
  padding-top: 5px;
  /*margin-left: 5%;*/
}

.k-multiselect .k-multiselect-wrap .k-searchbar, .k-dropdowntree .k-multiselect-wrap .k-searchbar {
  width: 23vw !important;
}

.display-none {
  display: none !important;
}

.text-over {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 95%;
}

.btn-border {
  border: 1px solid #0078d4;
  color: #0078d4;
}

.projecttakslist-grid {
  max-height: calc(100vh - 200px);
  width: 100% !important;
}

.automationproject-title {
  font-size: 1rem;
  margin-bottom: 5px;
  font-weight: 500;
  color: #333;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  float: left;
  position: relative;
  width: 90%;
  padding-top: 5px;
}

.automationtaskinfo-tile {
  float: left;
  color: #808997;
  padding-top: 5px;
  margin-right: 5%;
  margin-left: 2px;
}

.automationproject-tilesinfo {
  float: left;
  width: 25%;
  color: #808997;
  font-size: 11px;
  margin-top: 5px;
  font-family: Poppins,'sans-serif' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 15px;
  margin-bottom: 0px !important;
  letter-spacing: 0.6px
}

.pdf-dwnload {
  color: #F1416C !important;
  background-color: #FFF5F8 !important;
}

.k-grid th:first-child, .k-grid td:first-child {
  font-weight: 500;
}
/*.tabs-maxheight {
  max-height: calc(100vh - 190px);
  overflow: auto;
}
*/
.report-charttile {
  float: left;
  max-height: calc(100vh - 234px) !important;
  overflow: auto !important;
  padding: 0;
} 



.download-refile {
  position: absolute;
  height: calc(1.5em + 0.8rem + 0px);
  width: calc(1.5em + 0.8rem + 0px);
  border-radius: 30px;
  background: #ffffff !important;
  color: #a1a5b7;
  border: 1px solid #a1a5b7;
  cursor: pointer !important;
  top: 80px;
  margin-left: 50px;
  margin-bottom: 10px !important;
}

  .download-refile:hover {
    color: #187de4;
    border: 1px solid #187de4;
  }

.delete-refile {
  position: absolute;
  height: calc(1.5em + 1rem + 0px);
  width: calc(1.5em + 1rem + 0px);
  border-radius: 30px;
  background: #ffffff !important;
  color: #a1a5b7;
  border: 1px solid #a1a5b7;
  cursor: pointer !important;
  top: 80px;
  margin-left: 50px;
  margin-bottom: 10px !important;
}

  .delete-refile:hover {
    color: #f1416c;
    border: 1px solid #f1416c;
  }

.btn-delfiles {
  background: #f5f8fa;
  border: none;
  height: 30px;
  cursor: pointer;
}

  .btn-delfiles:hover {
    background: #f5f8fa;
    border: none;
    color: #a1a5b7;
  }

  .btn-delfiles i {
    color: #f1416c !important;
  }


    .btn-delfiles i:hover {
      color: #f1416c;
    }

.gridenable_filter {
  position: relative;
  right: 250px;
  display: block;
  font-weight: 500;
}

.tree-height {
  max-height: calc(100vh - 115px) !important;
  overflow: auto;
}

.k-listview-pager {
  border: 1px solid #eef0f8 !important;
}

.k-menu-group .k-item:hover, .k-menu-group .k-item.k-state-hover, .k-menu.k-context-menu .k-item:hover, .k-menu.k-context-menu .k-item.k-state-hover {
  color: #009ef7 !important;
  text-decoration: none;
  background-color: #f4f6fa !important;
}

.maintakslist-grid {
  max-height: calc(100vh - 218px) !important;
  overflow: auto;
}

.text-ovetflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
/*Project-card new design*/
.Projectlist-card {
  cursor: pointer;
  background: #ffffff;
  border: 1px solid #ebedf5 !important;
  border-radius: 2px;
  margin: 5px 5px 0px 5px;
}

  .Projectlist-card:hover {
    background: #ebf7fe;
    border: 1px solid #d5d8f5 !important;
  }

.start-dateindi {
  color: #009ef7 !important;
  opacity: 0.8 !important;
}

.right-taskinfo-tile {
  /* padding-top: 5px !important; */
  padding-bottom: 5px !important;
}
/*Project-card new design*/
.esignature_p {
  float: left;
  text-align: justify;
  width: 95%;
  margin-left: 10px;
  padding-top: 5px
}

.act_icon {
  padding: 3px 6px 4px 7px;
  background: #e8f1fb;
  border-radius: 2px;
  font-size: 13px;
}

.m-new-tabs {
  font-weight: 500 !important;
}

.aler-possition {
  position: relative !important;
}

.left-project-div kendo-listview {
  max-height: calc(100vh - 118px) !important;
}

.reportpadding-bar {
  padding: 0px 100px !important;
}

.cycles {
  display: flex;
  position: relative;
  float: right;
  background: #009ef7;
  padding: 2px;
  border-radius: 5px;
  font-size: 9px;
  color: #fff;
  font-weight: 500;
  border: 2px solid #cad3ef;
}

.k-grid {
  font-family: Poppins,'sans-serif' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/*.view_allfile .k-label {
  font-size: 14px !important;
}
*/
.file_collectdisable {
  background: transparent;
  opacity: 0.5;
  pointer-events: none;
}

.viewfile {
  border: 3px dotted #eee;
  padding: 5px 8px;
}

  .viewfile .k-checkbox {
    width: 1.2rem !important;
    height: 1.2rem !important;
  }

    .viewfile .k-checkbox::before {
      width: 1.5rem !important;
      height: 1.5rem !important;
    }

/* .bypass_unbpass .k-content {
  height: calc(100vh - 150px);
  overflow: auto;
}  */

.bypass_unbpass .k-content.k-window-content.k-dialog-content{
  padding-bottom: 0 !important;
}

.bypass_alert .alert {
  padding: 0;
  margin-bottom: 0;
}

.bypass-grid-height .k-grid-norecords {
  position: absolute;
  top: 45%;
  left: 45%;
}

.bypass-grid-height .k-grid-filter {
  padding-top: 15px !important;
}

.add_reference .k-upload {
  padding: 10px;
  border: 2px dotted #ddd;
}

.alert-positions {
  padding-left: 0px;
  position: relative !important;
}

FIELDSET {
  margin: 8px 0px;
  border: 1px solid silver;
  padding: 8px;
  border-radius: 4px;
}

LEGEND {
  padding: 2px;
  width: 15% !important;
  font-size: 1rem;
  font-weight: 600 !important;
}

.group-height {
  max-height: 30vh;
  overflow: auto;
}

.nodata-found {
  width: 25%;
  background: #fff;
  padding: 15px;
}

.nodata-found + h3{
  font-size: 1.5rem;
}

.assign_user {
  overflow: auto;
  overflow-x: hidden;
}

.width-48per {
  width: 48%;
}

.assign_usrbtn {
  display: flex;
  justify-content: end;
  padding: 5px 0 0px;
}



.k-scheduler-table .k-link {
  cursor: pointer;
  /*font-size: 15px !important;*/
}

.k-scheduler-header th {
  font-weight: 500 !important;
  font-size: 11px !important;
}

.k-scheduler-times th {
  font-weight: 500 !important;
  font-size: 11px !important;
}

kendo-scheduler.k-widget.k-scheduler.k-floatwrap {
  height: calc(100vh - 198px) !important;
}

kendo-scheduler .k-event, .k-event {
  color: #3b3b3b;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  /* width: 155px !important;*/
  font-size: 10px;
  font-weight: 500;
  background: aliceblue;
  padding-top: 4px;
  /*height: 20px !important;*/
  cursor: pointer;
}

.cal-meet-textover {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.cal-statuds {
  padding: 0px !important;
  margin-top: 2px !important;
  float: revert !important;
}


.setout_content {
  top: auto !important;
}

.chat-boby .main-div {
  position: initial !important;
  height: auto !important;
}

.main-div > .col-md-12 {
  padding: 0;
}

.chat-boby .task-btnshover {
  position: absolute;
  right: 115px;
  top: 49px;
  z-index: 9;
}

.alert_assign {
  display: flex;
  justify-content: end;
  margin-right: 26px;
  margin-top: -10px;
}

.taskinfo_detailstext {
  color: #787979;
  margin-left: 5%;
  float: left;
  margin-top: 5px;
  font-family: Poppins,'sans-serif' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 0px !important;
  padding-left: 5px;
  padding-right: 5px;
  -webkit-font-smoothing: antialiased;
  /*width: 10% !important;*/
}

.search_btn {
  margin-left: 3px;
  padding: 5px 8px;
  font-size: 18px;
  height: 35px;
  background: #f9f9f9;
  box-shadow: none !important;
  transition: all 0.2s ease-in-out;
}

 /* .search_btn:hover {
    background: #eee;
  }*/

.search_box {
  display: flex;
  padding-left: 5px;
  margin-top: 5px;
}

.search_cancel {
  position: absolute;
  top: 8px;
  left: 28.2% !important;
  background: #fff;
  border-radius: 5px;
  margin-top: 1px;
  font-size: 12px;
  color: red;
  text-decoration: none !important;
  cursor: pointer;
}
.grid_search_cancel {
  position: absolute;
  top: 8px;
  left: 76.2% !important;
  background: #fff;
  border-radius: 5px;
  margin-top: 1px;
  font-size: 12px;
  color: red;
  text-decoration: none !important;
  cursor: pointer;
}
.selected-items {
  color: #187de4;
  border: 2px dashed #eef0f8;
  border-radius: 3px;
  font-size: 10px;
}


/*===managepassward style===*/
.change_pwd {
  box-shadow: 0px 0px 30px 0px rgb(82 63 105 / 5%);
  background: #fff !important;
  border: 1px solid #f9f4f4;
}

  .change_pwd ul {
    background: #fff !important;
    border-bottom: 1px solid #f9f4f4;
  }

    .change_pwd ul li {
      padding: 3px 10px 3px !important;
    }

      .change_pwd ul li:hover {
        border: 1px solid transparent !important;
      }

  .change_pwd .k-tabstrip-items .k-item {
    border-bottom: 2px solid #fdfdfe !important;
  }

    .change_pwd .k-tabstrip-items .k-item.k-state-active {
      border-color: #009ef7 !important;
    }

.hyperlink-css {
  cursor: pointer;
  color: #0078d4 !important;
}


/*--Start Workflow-graph style--*/
.ngx-charts-outer {
  width: 100% !important;
}

.ngx-charts {
  width: 100% !important;
}

/*--End Workflow-graph--*/



.icon_boximg {
  width: 95px;
  height: 95px;
  float: left;
  padding: 20px 10px 10px 0px;
}

.projecttitlecss {
  font-size: 0.95vw;
  font-weight: 500;
  padding: 16px 15px 10px 10px;
  position: absolute;
}

.project_count {
  line-height: 0px;
  width: 24px;
  height: 24px;
  margin-left: 3px;
  font-size: 0.8vw;
  border-radius: 50px;
  text-align: center;
  padding: 12px 15px 10px 7px;
  color: #fff;
  font-weight: 500;
  float: right;
  margin-right: 2px;
  margin-top: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.project_count_pending {
  background: #ffc800;
}

.project_count_inprogress {
  background: #009ff7;
}

.project_count_completed {
  background: #4fce8a;
}

.project-countno {
  margin-left: 85%;
  font-size: 15px;
  font-weight: 500;
  position: relative;
  margin-top: 22px;
  display: flex;
}

.total-countscss {
  margin-left: 48%;
  font-size: 0.7vw;
  font-weight: 500;
}

.dashboard-block {
  display: block;
  position: absolute;
}

.dashboard-cards {
  cursor: pointer;
  background: #ffffff;
  border: 1px solid #ebedf5 !important;
  border-radius: 2px;
  margin: 23px;
  /*width: 22.3%;*/
  width: calc(100% - 78.7vw) !important;
  height: 112px !important;
}

.projectTile {
  background: #f1faff;
  color: #009ff7;
}

.taskTile {
  background: #e8fff3;
  color: #4fce8a;
}

.dalTile {
  background: #fff8dc;
  color: #ffc800;
}

.formprojectTile {
  background: #ffeae9;
  color: #ff5454;
}

.adminTile {
  background: #f8f5fe;
  color: #7336eb;
}

.reportTile {
  background: #f8f5fe;
  color: #7336eb;
}

.taskinfo_detailstex {
  font-size: 12px;
  /* font-weight: 500;*/
  padding: 2px;
  display: block;
  cursor: pointer;
  margin-bottom: 1px !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 2px !important;
  float: left;
  color: #787979;
  width:90%;
}
  .taskinfo_detailstex:hover {
    color: #0078d4;
  }

  .projecttask-list {
    /*max-height: calc(100vh - 120px) !important;*/
    width: 100% !important;
    height:auto;
  }

.taskinfo_taskhead {
  font-size: 12px;
  font-weight: 500;
  padding: 2px;
  display: block;
  cursor: pointer;
  margin-bottom: 1px !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 2px !important;
}

.left-projectlist {
  margin-top: -34px !important;
}

.sheduler_calndr .k-event-actions {
  display: none;
}

.dalnodata-found {
  width: 75%;
  /* background: #fff;*/
  padding: 15px;
  text-align: center;
  padding: 25px;
  margin-left: 25px;
}

.acmi-info {
  border: 1px solid #f2f2f2;
  padding: 10px;
  background: #f6f6f6;
  margin-bottom: 15px;
  border-radius: 5px;
}

.acmilist-gri {
  max-height: calc(100vh - 190px);
  overflow: auto;
}

.number_of_info {
  color: #808ba4;
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 0;
}
.taskowner-info {
  color: #787979;
  /*margin-right: 5%;*/
  margin-left: 5%;
  float: left;
  margin-top: 5px;
  font-family: Poppins,'sans-serif' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 0px !important;
  padding-left: 5px;
  padding-right: 5px;
  -webkit-font-smoothing: antialiased;
}

.projecttasklist-height {
  /* height: auto; */
  max-height: calc(100vh - 215px);
  overflow: auto;
  overflow-x: hidden;
}


.projecttask-rightdiv .tasklist-totaldiv-height {
  height: calc(100vh - 200px) !important;
  overflow: auto;
}

.projects-listheight + kendo-datapager {
  position: fixed !important;
  bottom: 17px;
  width: 94.9%;
  margin-left:-15px;
}

kendo-listview.project-listviewdisplay + kendo-datapager {
  position: fixed !important;
  bottom: 32px;
  width: 94.5%;
  margin-left: -14px;
}


kendo-listview.project-listviewdisplay kendo-datapager.k-listview-pager.k-pager-wrap.k-pager.k-widget {
  position: fixed !important;
  bottom: 30px;
  width: 95%;
  margin-left: -14px;
}

.nav-body.body-pd .projects-listheight + kendo-datapager{
  max-width: calc(100vw - 238px) !important;
}

@media screen and (max-width: 1366px) {
  .k-listview-content.k-d-flex.k-flex-col.k-flex-nowrap.project-info.projects-listheight.project-listviewdisplay {
    height: calc(100vh - 40vh) !important;
  }
}
@media screen and (max-width: 1366px) {
  .projects-listheight + kendo-datapager {
    /* width: 94% !important; */
    max-width: calc(100% - 82px) !important;
  }
}


@media screen and (min-width: 768px) {
  .projectlist-cards {
    width: calc(100% - 54%) !important;
    height: calc(100vh - 72%) !important;
  }
}

@media screen and (min-width: 1920px) {
  .projectlist-cards {
    width: calc(100% - 79%) !important;
    height: calc(100vh - 79vh) !important;
  }
}

@media only screen and (min-width: 960px) {
  .projectlist-cards {

    width: calc(100% - 77%) !important;
    height: calc(100vh - 74%) !important;
    margin-right: 15px !important;
  }
}

@media only screen and (min-width: 1440px) {
  .projectlist-cards {
    width: calc(100% - 77%) !important;
    height: calc(100vh - 72%) !important;
  }
}

@media only screen and (min-width: 2000px) {
  .projectlist-cards {
    width: calc(100% - 79%) !important;
    height: calc(100vh - 72%) !important;
  }
}

@media only screen and (max-device-width: 480px) {
  .projectlist-cards {
    width: calc(100% - 79%) !important;
    height: calc(100vh - 72%) !important;
  }
}

@media only screen and (device-width: 768px) {
  .projectlist-cards {
    width: calc(100% - 79%) !important;
    height: calc(100vh - 72%) !important;
  }
}
/* different techniques for iPad screening */
@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:portrait) {
  .projectlist-cards {
    width: calc(100% - 79%) !important;
    height: calc(100vh - 72%) !important;
  }
}

@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:landscape) {
  .projectlist-cards {
    width: calc(100% - 79%) !important;
    height: calc(100vh - 72%) !important;
  }
}
.listview_total_height95 {
  /* height: 75vh !important; */
  height: calc(100vh - 211px)!important;
  overflow: hidden;
}

.listview_total_height16 {
  /* height: calc(100vh - 30vh) !important; */
  height: calc(100vh - 215px) !important;
  overflow-y: auto;
  overflow-x: hidden !important;
  margin-bottom: 0px;
  width: 100%;
}



dashboard-cards {
  cursor: pointer;
  background: #ffffff;
  border: 1px solid #ebedf5 !important;
  /*border-radius: 2px;*/
  margin: 15px 23px 15px 23px;
  /*width: 22.3%;*/
  width: calc(100% - 78vw) !important;
  height: 112px !important;
  border-radius: 5px;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
}

.dashbord-listheight {
  height: calc(100vh - 290px);
  overflow: auto;
  background: #fff;
}


.projectlist-cards {
  cursor: pointer;
  background: #ffffff;
  border: 1px solid #ebedf5 !important;
  /*border-radius: 2px;*/
  margin: 10px 18px 10px 10px;
  /*width: 22.3%;
  width: calc(100% - 74vw) !important*/
  border-radius: 5px;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
  cursor: pointer;
  /*height: calc(100vh - 82vh) !important;*/
  /* display: inline-table;*/
  padding: 10px;
}

.TotalProjects {
  font-size: 0.8vw;
  font-weight: 500;
  /* padding: 8px 14px 10px 10px; */
  position: absolute;
  margin-left: 50%;
  top: 50px;
}

.TotalTasks {
  font-size: 0.8vw;
  font-weight: 500;
  position: absolute;
  margin-left: 48%;
  top: 50px;
  color: #4fce8a;
}

.Totalfiles {
  font-size: 0.8vw;
  font-weight: 500;
  position: absolute;
  margin-left: 49%;
  top: 50px;
  color: #ffc700;
}

.TotalReports {
  font-size: 0.8vw;
  font-weight: 500;
  position: absolute;
  margin-left: 46%;
  top: 50px;
  color: #f13f6c;
}

.Projects {
  font-size: 1.2vw;
  font-weight: 500;
  padding: 32px 15px 10px 10px;
  position: absolute;
  margin-left: 0%;
  margin-top: 12px;
  color: #009ff7;
}

.Tasks {
  font-size: 1.2vw;
  font-weight: 500;
  padding: 32px 15px 10px 10px;
  position: absolute;
  margin-left: 0%;
  margin-top: 12px;
  color: #4fce8a;
}

.DigitalAssetLibrary {
  font-size: 1.2vw;
  font-weight: 500;
  padding: 32px 15px 10px 10px;
  position: absolute;
  margin-left: 0%;
  margin-top: 12px;
  color: #ffc700;
}

.AdminSettings {
  font-size: 1.2vw;
  font-weight: 500;
  padding: 32px 15px 10px 10px;
  position: absolute;
  margin-left: 0%;
  margin-top: 12px;
  color: #7336eb;
}

.FormsConfiguration {
  font-size: 1.2vw;
  font-weight: 500;
  padding: 32px 15px 10px 10px;
  position: absolute;
  margin-left: 0%;
  margin-top: 12px;
  color: #ff5454;
}

.Reports {
  font-size: 1.2vw;
  font-weight: 500;
  padding: 32px 15px 10px 10px;
  position: absolute;
  margin-left: 0%;
  margin-top: 12px;
  color: #f13f6c;
}

.projectcard-title {
  /*font-size: 1.2vw;*/
  margin-bottom: 0px;
  font-weight: 500;
  color: #333;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  float: left;
  position: relative;
  margin-top: 20px;
  width: 100% !important;
}

.projectcard-tilesinfo {
  float: left;
  color: #808997;
  font-size: 9px !important;
  margin-top: 5px;
  font-family: Poppins,'sans-serif' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 0px !important;
  letter-spacing: 1px;
  /* padding-left: 5px; */
  padding-right: 5px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-transform: uppercase; */
  width: 98%;
}

.projectcard-temdetails {
  float: left;
  color: #808997;
  font-size: 9px !important;
  margin-top: 5px;
  font-family: Poppins,'sans-serif' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 0px !important;
  letter-spacing: 1px;
  padding-left: 0px;
  padding-right: 5px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-transform: uppercase;
}

.projectcardbotm-temdetails {
  float: left;
  color: #808997;
  font-size: 0.8rem !important;
  margin-top: 5px;
  font-family: Poppins,'sans-serif' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 0px !important;
  letter-spacing: 1px;
  padding-left: 0px;
  padding-right: 5px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-transform: uppercase;
  width: 32%;
}

.cardproject-newstatus {
  padding-left: 0px !important;
}

.card-totaltask {
  float: left;
  color: #000000;
  font-size: 0.9rem !important;
  margin-top: 5px;
  font-family: Poppins,'sans-serif' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 0px !important;
  letter-spacing: 0.2px;
  padding-left: 0px;
  padding-right: 5px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*text-transform: uppercase;*/
}

.projectcard-moleculename {
  float: left;
  color: #808997;
  font-size: 0.9rem !important;
  margin-top: 5px;
  font-family: Poppins,'sans-serif' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 0px !important;
  letter-spacing: 1px;
  padding-left: 0px;
  padding-right: 5px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-transform: uppercase;
  width: 100%;
  height: 15px;
}


.taskTile {
  background: #e8fff3;
  color: #4fce8a;
}

.dalTile {
  background: #fff8dc;
  color: #ffc800;
}

.formprojectTile {
  background: #ffeae9;
  color: #ff5454;
}

.adminTile {
  background: #f8f5fe;
  color: #7336eb;
}

.reportTile {
  background: #f8f5fe;
  color: #7336eb;
}

/*Status for Card*/
.project-statusdiv{

}
.Cal_Pending {
  color: #ffc700 !important;
  
  margin-left: 5px;
  margin-right: 5px;
}

.Cal_InProgress {
  color: #009ef7 !important;
 
  margin-left: 5px;
  margin-right: 5px;
}

.Cal_Deleted {
  color: #f6a96d !important;
 
  margin-left: 5px;
  margin-right: 5px;
}

.Cal_Completed {
  color: #50cd89 !important;
  
  margin-left: 5px;
  margin-right: 5px;
}

.Cal_Approved {
  color: #558dca !important;
  margin-left: 5px;
  margin-right: 5px;
}

.Cal_Rejected {
  color: #f1416c !important;
  margin-left: 5px;
  margin-right: 5px;
}

.Cal_CycleSuspended, .Suspended {
  color: #8cbabb !important;
  margin-left: 5px;
  margin-right: 5px;
}

.Cal_Suspended {
  color: #8cbabb !important;
  margin-left: 5px;
  margin-right: 5px;
}

.Cal_Bypassed {
  color: #a1a5b7 !important;
  margin-left: 5px;
  margin-right: 5px;
}


.Cal_Cancelled {
  color: #f1416c !important;
  margin-left: 5px;
  margin-right: 5px;
}

.Pending {
  color: #fff !important;
  background-color: #ffc700 !important;
  padding: 2px 4px 2px 4px;
  text-align: left;
  font-size: 9px;
  float: left;
  margin-top: 5px;
  font-size: 9px;
  padding-left: 5px;
  margin-left: 20px;
  border-radius: 2px;
}

.Draft {
  color: #fff !important;
  background-color: #8a2be2 !important;
  padding: 2px 4px 2px 4px;
  text-align: left;
  font-size: 9px;
  float: left;
  margin-top: 5px;
  font-size: 9px;
  padding-left: 5px;
  margin-left: 20px;
  border-radius: 2px;
}

.Draft95 {
  color: #fff !important;
  background-color: #8a2be2 !important;
  padding: 2px 4px 2px 4px;
  text-align: left;
  font-size: 9px;
  float: left;
  margin-top: 0px;
  font-size: 9px;
  padding-left: 5px;
  border-radius: 2px;
}




.InProgress {
  color: #fff !important;
  background-color: #009ef7 !important;
  border-radius: 2px;
  padding: 2px 4px 2px 4px;
  text-align: left;
  font-size: 9px;
  float: left;
  margin-top: 5px;
  padding-left: 5px;
  margin-left: 20px;
}

.Deleted {
  color: #fff !important;
  background-color: #f6a96d !important;
  border-radius: 2px;
  padding: 2px 4px 2px 4px;
  text-align: left;
  font-size: 9px;
  float: left;
  margin-top: 5px;
  padding-left: 5px;
  margin-left: 20px;
  border-radius: 4px;
}

.Completed {
  color: #fff !important;
  background-color: #50cd89 !important;
  border-radius: 2px;
  padding: 2px 4px 2px 4px;
  text-align: left;
  font-size: 9px;
  float: left;
  margin-top: 5px;
  padding-left: 5px;
  margin-left: 20px;
}

.Approved {
  color: #fff !important;
  background-color: #558dca !important;
  border-radius: 2px;
  padding: 2px 4px 2px 4px;
  text-align: left;
  font-size: 9px;
  float: left;
  margin-top: 5px;
  padding-left: 5px;
  margin-left: 20px;
}

.Rejected {
  color: #fff !important;
  background-color: #f1416c !important;
  border-radius: 2px;
  padding: 2px 4px 2px 4px;
  text-align: left;
  font-size: 9px;
  float: left;
  margin-top: 5px;
  padding-left: 5px;
  margin-left: 20px;
}

.CycleSuspended, .Suspended {
  color: #fff !important;
  background-color: #8cbabb !important;
  border-radius: 2px;
  padding: 2px 4px 2px 4px;
  text-align: left;
  font-size: 9px;
  float: left;
  margin-top: 5px;
  padding-left: 5px;
  margin-left: 20px;
}

.Suspended {
  color: #fff !important;
  background-color: #8cbabb !important;
  border-radius: 2px;
  padding: 2px 4px 2px 4px;
  text-align: left;
  font-size: 9px;
  float: left;
  margin-top: 5px;
  padding-left: 5px;
  margin-left: 20px;
}

.Bypassed {
  color: #fff !important;
  background-color: #a1a5b7 !important;
  border-radius: 2px;
  padding: 2px 4px 2px 4px;
  text-align: left;
  font-size: 9px;
  float: left;
  margin-top: 5px;
  padding-left: 5px;
  margin-left: 20px;
}


.Cancelled {
  color: #fff !important;
  background-color: #f1416c !important;
  border-radius: 2px;
  padding: 2px 4px 2px 4px;
  text-align: left;
  font-size: 9px;
  float: left;
  margin-top: 5px;
  padding-left: 5px;
  margin-left: 20px;
}


.Pending95 {
  color: #fff !important;
  background-color: #ffc700 !important;
  border-radius: 2px;
  padding: 1px 5px 1px 5px;
  text-align: left;
  font-size: 10px;
  float: left;
  margin-top: 0px;
}

.InProgress95 {
  color: #fff !important;
  background-color: #009ef7 !important;
  border-radius: 2px;
  padding: 1px 5px 1px 5px;
  text-align: left;
  font-size: 10px;
  float: left;
  margin-top: 0px;
}
 .Completed95 {
  color: #fff !important;
  background-color: #50cd89 !important;
  border-radius: 2px;
  padding: 1px 5px 1px 5px;
  text-align: left;
  font-size: 10px;
  float: left;
  margin-top: 0px;
}


 .Approved95 {
  color: #fff !important;
  background-color: #558dca !important;
  border-radius: 2px;
  padding: 1px 5px 1px 5px;
  text-align: left;
  font-size: 10px;
  float: left;
  margin-top: 0px;
}

 .Rejected95 {
  color: #fff !important;
  background-color: #f1416c !important;
  border-radius: 2px;
  padding: 1px 5px 1px 5px;
  text-align: left;
  font-size: 10px;
  float: left;
  margin-top: 0px;
}

 .CycleSuspended95, .Suspended95 {
  color: #fff !important;
  background-color: #8cbabb !important;
 
  border-radius: 2px;
  padding: 1px 5px 1px 5px;
  text-align: left;
  font-size: 10px;
  float: left;
  margin-top: 0px;
}

 .Suspended95 {
  color: #fff !important;
  background-color: #8cbabb !important;
  border-radius: 2px;
  padding: 1px 5px 1px 5px;
  text-align: left;
  font-size: 10px;
  float: left;
  margin-top: 0px;
}
.Deleted95 {
  color: #fff !important;
  background-color: #f6a96d !important;
  border-radius: 2px;
  padding: 1px 5px 1px 5px;
  text-align: left;
  font-size: 10px;
  float: left;
  margin-top: 0px;
}

 .Bypassed95 {
  color: #fff !important;
  background-color: #a1a5b7 !important;
  border-radius: 2px;
  padding: 1px 5px 1px 5px;
  text-align: left;
  font-size: 10px;
  float: left;
  margin-top: 0px;
}
 .Cancelled95 {
  color: #fff !important;
  background-color: #f1416c !important;
  border-radius: 2px;
  padding: 1px 5px 1px 5px;
  text-align: left;
  font-size: 10px;
  float: left;
  margin-top: 0px;
}
.workflow-legends {
  width: 10px !important;
  position: relative;
  float: left;
  height: 10px;
  border-radius: 25px;
  margin-top: 4px;
}



.project-listviewdisplay {
  float: left;
  display: block;
}

kendo-tabstrip.main-tabs {
  padding: 10px 10px 10px 10px;
}

.k-listview-content.k-d-flex.k-flex-col.k-flex-nowrap.project-info.projects-listheight.project-listviewdisplay {
  height: calc(100vh - 30vh) !important;
  overflow: auto;
 /* margin-bottom: 20px;*/
  padding-left: 5px;
  margin-top:5px;
}

.project-search {
  width: 15%;
  margin-left: 5px;
  float: left;
}

.search-btn_new {
  position: relative;
  top: 0px;
  left: 87% ;
  color: #a1a5b7;
  background: none;
  margin-top: 0px;
  border-left: 1px solid #dbdbdb;
  cursor:pointer;
}

  .search-btn_new:hover {
    color: #009ef7;
  }

  .dal_listsearch_btn44{
    left: 167% ;
  }

.search_cancel:hover {
  color: red;
}


.k-link p {
  margin-bottom: 0rem !important;
}

.btn-excelnew {
  border-radius: 3px;
  margin-top: 0px;
  box-shadow: none;
  margin-left: 5px;
  cursor: pointer;
  float: left;
}

.p-grid-search-cancel {
  position: absolute;
  top: 10px;
  left: 245px !important;
  /* background: #fff;*/
  border-radius: 5px;
  margin-top: 1px;
  /*font-size: 20px;*/
  color: red;
  text-decoration: none !important;
}

p-grid-search-cancel :hover {
  color: red;
}

.topnew-strip16 {
  border-bottom: 1px solid #fff;
  width: 100%;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px;
  z-index: 7;
  margin-top: 2px;
}


.task-action-btn {
  border: 1px solid !important;
  margin-top: 2px;
  border-radius: 20px;
  background: #009ef7 ;
  color: #fff;
  cursor: pointer;
}
  .task-action-btn:hover {
    background: #2884EF;
    color: #fff;
  }


.action-btn-tasks1:hover {
  background: aliceblue;
  color: #1976d2;
  cursor: pointer !important;
}

.list-searchbar {
  flex: 0 0 33.333333%;
  width: 33.333333%;
}

.search-bar-width {
  width: 33.333333% !important;
}

.newdalsearch33 {

  width: 45% !important;

}

.dalsearch33 {

  width: 51% !important;

}
.grid-searchbar44 {
  width: 60% !important;
}
.grid_search_cancel33 {
  left: 21.5% !important;
}
.dal_search_btn44 {
 margin-left:79% !important;
}
.grid_search_cancel44 {
  margin-left: -25% !important;
}
.newdalsearch44 {
  width: 60% !important;
}
.dal_pop_height {
  /* height: 70vh; */
  height: calc(100vh - 223px);
  overflow: auto;
}

.list-search_cancel33 {
  position: absolute;
  top: 8px;
  left: 37.2% !important;
  background: #fff;
  border-radius: 5px;
  margin-top: 1px;
  font-size: 12px;
  color: red;
  text-decoration: none !important;
  cursor: pointer;
}
  .dal-listexpot33 {
    margin-right: 0px !important;
  float:right;
}
/*.dal_pop_listview_height{
    height:65vh;
    overflow:auto;

}*/

.k-listview-content.k-d-flex.k-flex-col.k-flex-nowrap.project-info.dal_pop_listview_height {
  /* height: 65vh; */
  max-height: calc(100vh - 278px) !important;
  overflow: auto;
}
  .asset_library44 {
    margin: 10px 0px 10px 0px;
  }
.dal_pop_info_height45 {
  height: 42vw !important;
  overflow: auto;
}

.grid-dalsearch33 {
  width: 33% !important;
}

.grid-searchbar95 {
  width: 32% !important;
}

.grid_search_cancel95 {
  left: 27% !important;
}

.grid-searchbar33 {
  width: 60% !important;
}

.grid-dalexpot33 {
  margin-right: 0px !important;
}

.grid-dalexpot95 {
  margin-top: -2px !important;
}

.dal-listexpot95 {
  float: right;

  margin-top: 0px !important;

}

/*.grid-searchbar39 {
  width: 34% !important;
}
*/
.grid_search_cancel39 {
  position: absolute;
  top: 8px;
  left: 24.2% !important;
  background: #fff;
  border-radius: 5px;
  margin-top: 1px;
  font-size: 12px;
  color: red;
  text-decoration: none !important;
  cursor: pointer;
}

.taskinfo-back17 {
  margin-top: 6px !important;
  margin-left: 25% !important;
  z-index:99;
}

.filter-search {
  background-color: #f7f7f7;
  margin-top: 2px;
  height: 34px;
  margin-left: 5px;
  font-size: 15px;
  border: 0;
  float: left;
}
/*.gridfilter-search39 {
    margin-left:20px !important;
}*/
.listselected-items {
  float: left;
  margin-top: 1px;
  color: #1976d2;
  font-size: 11px;
}
.listview_total_height17 {
  margin-top: 20px !important;
}
.grid_total_height95{
    /* height:70vh !important; */
    height: calc(100vh - 196px)!important;;
}

/*.dal-listview-img {
  background: #fff;
  border: 1px solid #d1d4d7;
  margin-top: 5px;
  margin-bottom: 5px;
  border-radius: 5px;
  position: absolute;
}
*/.dal-card {
  border-radius: 5px;
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 5%), 0 6px 6px 0 rgb(0 0 0 / 5%);
  margin-bottom: 5px;
  border: 1px solid #d4d4d4 !important;
}
.headding17{
    margin-top:10px !important;
}
.headding66 {
  margin-top: 12px !important;
}
.headding39{
    margin-top:10px !important;
}

.grid_total_height67{padding-left:0;padding-right:0;}
.grid_total_height68{padding-left:0;padding-right:0;}
.grid_total_height87{padding-left:0;padding-right:0;}
.grid_total_height89{padding-left:0;padding-right:0;}
.grid_total_height92{padding-left:0;padding-right:0;}

.advanced_save_srch67 {
  flex: 0 0 50%;
  max-width: 50%;
  padding: 0 15px;
}
.advanced_savename_srch67{
  flex: 0 0 50%;
  max-width: 50%;
  padding: 0 15px;
}

.advanced_save_srch95 {
  flex: 0 0 25%;
  max-width:25%;
  padding: 0 15px;
}
.advanced_savename_srch95{
  flex: 0 0 25%;
  max-width: 25%;
  padding: 0 15px;
}

.advanced_save_srch33 {
  flex: 0 0 25%;
  max-width:25%;
  padding: 0 15px;
}
.advanced_savename_srch33{
  flex: 0 0 25%;
  max-width: 25%;
  padding: 0 15px;
}

.advanced_save_srch39 {
  flex: 0 0 25%;
  max-width: 25%;
  padding: 0 15px;
}
.advanced_savename_srch39{
  flex: 0 0 25%;
  max-width:25%;
  padding: 0 15px;
}

.dropdown_svd{
  position: absolute;
  top:41px !important;
  left:213px !important;
  right: 0;
  transition: 0.8s ease-in-out;
  z-index: 999;
}

.dropdown_svd .k-popup{
  padding: 2px;border: 0 !important;
  box-shadow: 0 0 5px #eee;
}

.btnsaveadvns67{
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
  padding:10px 15px 0 15px;
  justify-content: end;
  display: flex;
}

.btnsaveadvns95{
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
  padding-top:28px;
}

.btnsaveadvns33{
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
  padding-top:28px;
}

.btnsaveadvns39{
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
  padding-top:28px;
}

.svd_advscontrol80 {
  flex: 0 0 50%;
  max-width: 50%;
  padding: 0 15px;
}


.svd_advscontrol67{
  flex: 0 0 50%;
  max-width: 50%;
  padding: 0 15px;
}

.svd_advscontrol95{
  flex: 0 0 25%;
  max-width: 25%;
  padding: 0 15px;
}

.svd_advscontrol33{
  flex: 0 0 25%;
  max-width: 25%;
  padding: 0 15px;
}

.svd_advscontrol39{
  flex: 0 0 25%;
  max-width: 25%;
  padding: 0 15px;
}

.default_check67{
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
  padding: 0 0px 0 20px;
  margin-bottom: 0;
  margin-top: 10px
}

.reassing_audit_btn80 {
  background: #009ef7 !important;
  box-shadow: none;
}
.reassing_audit_btn80:hover{
  background: #2884EF !important;
}

.default_check95{
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
  padding: 0 20px;
  margin-bottom: 0;
  margin-top: 30px
}

.default_check39{
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
  padding: 0 20px;
  margin-bottom: 0;
  margin-top: 30px
}

.default_check33{
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
  padding: 0 20px;
  margin-bottom: 0;
  margin-top: 30px
}

#ReviewToolRefresh {
  padding: 0px 5px 0px;
  border: 0;
  font-size: 18px;
  border-radius: 4px;
  display: block;
  margin-right: 2px;
  background:transparent;
}

.disable-treenode {
  background: #f4f6fa;
  cursor: not-allowed;
}


.dal_grid_list{
    width:72vw !important;
    display:block;
}
.nav-body .left-project-div .dal-show_hide_btn31.action1 {
  border: none;
  padding: 6px;
  background: #009ef7;
  color: #fff;
  transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
  border-radius: 5px 5px 0px 0px;
  float: right;
  position: relative;
  margin: 300px -82px;
  z-index: 999;
}
.dal-show_hide_btn43 {
  border: none;
  padding: 6px;
  background: #009ef7;
  color: #fff;
  transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
  border-radius: 5px 5px 0px 0px;
  float: right;
  position: relative;
  margin: 300px -82px;
  z-index: 999;
  font-weight:500;
}
.dalside_info_height {
  height: 72vw;
  overflow: auto;
}
.da_pop_info_height45 {
  height: 85vh;
  overflow: auto;
}


.nav-body .left-project-div .dal-show_hide_btn31 {
  border: none;
  padding: 7px;
  background: #009ef7;
  color: #fff;
  transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
  border-radius: 5px 5px 0px 0px;
  float: right;
  position: relative;
  margin: 300px -82px;
  z-index: 999;
}
.left-project-div.MyClass_new {
  width: 0px !important;
  flex: 0;
  border: 0 !important;
  transition: 0.5s;
}

  .left-project-div.MyClass_new app-dynamic-field-tree {
    display: none;
    transition: 0.5s;
  }
.k-button {
  font-family: Poppins,'sans-serif' !important;
  border: 0;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/*.nav-body .nav-link span.nav_name {
  
}*/
.nav-body.body-pd .nav-link span.nav_name {
  display: -webkit-inline-box;
  transition: 0.5s;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
}
.l-navbar .nav-link span.nav_name {
  display: none;
  transition: 0.5s;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
}
.nav-body.body-pd .nav-link.collapse.show span.nav_name {
  display: none;
  transition: 0.5s;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
}

.thamb33 {
 /* background: #eef0f8;*/
  border-radius: 4px;
  padding: 5px;
  margin-bottom:0px !important;
}
 
.thamb61 {
  background: #edeef7;
  border-radius: 4px;
  /* width: 60% !important; */
  display: flex;
}



.checklist-approve37 {
  width: 12%;
  position: relative;
  left: 28%;
  display: block;
  top: -10px;
}



.thamb37 {
  background: #edeef7;
  border-radius: 4px;
  width: 50% !important;
  display: block;
  margin: 0 auto;
}

.checklist-approve3313 {
  width: 100%;
  position: absolute;
  left: 0px;
  z-index: 99;
}
.checklist-approve3314 {

  width: 55%;
  position: absolute;
  left: 10px;
  z-index: 99;
  top: 5px;
  left: 5px;
}

.checklist-approve4413 {
  width: 100%;
  position: absolute;
  left: 0px;
  z-index: 99;
}

.checklist-approve4414 {
  width: 55%;
  position: absolute;
  left: 10px;
  z-index: 99;
  top: 5px;
  left: 5px;
}

.listview_total_height33{
    padding-left:15px !important;
    padding-right:15px !important;
}
.asset_library33 {
  margin: 10px 0px;
  position: relative;
  /*display: flex;*/
}
.k-listview {
  font-family: Poppins,'sans-serif' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.k-expander {
  font-family: Poppins,'sans-serif' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.reports_view{
  width: 50%;
  display: inline-block;
}


.report_filtr{
    background-color: #fff;
    color: #0078d4;
    margin-top: 5px;
    margin-left: 10px;
    font-size: 15px;
    border: 0;
    padding: 4px 6px 0px;
    box-shadow: 0 0 5px #e7e5e5;
    z-index: 1;
}

.report_filtr:hover{
  background-color:#fdfdfd;
}
/*.custom-loader {
  width: 50px;
  height: calc(50px*0.866);
  background: #009ef7;
  clip-path: polygon(50% 0,100% 100%,0 100%);
  --c: at 50% 66%,#000 60deg,#0000 0;
  -webkit-mask: conic-gradient(from 30deg var(--c)) right, conic-gradient(from 150deg var(--c)) bottom, conic-gradient(from -90deg var(--c)) left, linear-gradient(#000 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  -webkit-mask-repeat: no-repeat;
  animation: cu9 1.5s infinite;
}

@keyframes cu9 {
  0% {
    -webkit-mask-size: 0 0,0 0,0 0,auto
  }

  13.33% {
    -webkit-mask-size: 100% 100%,0 0,0 0,auto
  }

  26.67% {
    -webkit-mask-size: 100% 100%,100% 100%,0 0,auto
  }

  40%, 60% {
    -webkit-mask-size: 100% 100%,100% 100%,100% 100%,auto
  }

  73.33% {
    -webkit-mask-size: 0 0,100% 100%,100% 100%,auto
  }

  86.67% {
    -webkit-mask-size: 0 0,0 0,100% 100%,auto
  }

  100% {
    -webkit-mask-size: 0 0,0 0,0 0,auto
  }
}
*/
.td.k-touch-action-auto {
  font-weight: 500;
}
.k-button.k-state-selected, .k-button-secondary.k-state-selected {
  border-color: #007bff;
  color: #ffffff;
  background-color: #007bff;
}
.nav-body.body-pd .dalsearch95 {
  flex: 0 0 34.333333%;
  width: 34.333333%;
}

.nav-body.body-pd .dal_search_btn95 {
  left: 83% !important;
}
.nav-body.body-pd .grid-dalsearch95 {
  flex: 0 0 34.333333%;
  width: 34.333333%;
}
span.k-icon.k-menu-expand-arrow.k-i-arrow-60-down.ng-star-inserted {
  display: none !important;
}
.nav-body .grid-searchbar39 {
  flex: 0 0 35.333333% !important;
  width: 35.333333% !important;
}
.nav-body .grid-dalsearch39 {
  width: 38% !important;
}

.nav-body .dal_search_btn39 {
  left: 74% !important;
}
.nav-body.body-pd .grid-dalsearch39 {
  width: 40% !important;
}

.nav-body.body-pd .dal_search_btn39 {
  left: 69% !important;
}
.k-textbox {
  font-family: Poppins,'sans-serif' !important;
}
.keywordsearch_tasks39 {
  flex: 0 0 100% !important;
  max-width: 100% !important;
}
.grid-searchbar33 {
  width: 30% !important;
}
.grid-searchbar33 {
  width: 30% !important;
}
.dal_search_btn33 {
  left: 74% !important;
}
.dal_listsearch_btn33 {
  left: 78% !important;
}

.notification_chat {
  font-size: 43px;
  position: absolute;
  top: -23px;
  right: -33px;
  color: red;
}

.hover_chatmsg {
  opacity: 0;
  display: none;
  position: absolute;
  right:-5px;
  top: 12px;
  width: 550px;
  background: #fff;
  padding: 15px;
  box-shadow: 0 0 5px #ddd;
  z-index:11;
  border-radius:2px;
}

  .hover_chatmsg h4 {
    padding-bottom: 8px;
    margin-bottom: 10px;
    border-bottom: 1px solid #eee;
  }

.date_useuntl {
  float: left;
  width: 15%;
  color: #808997 !important;
  padding-left: 5px;
  padding-top: 5px;
  
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 10px !important;
}



.top-submenu16:hover .hover_chatmsg {
  opacity: 1;
  display: block;
} 


.task_details_tabs.k-tabstrip-items-wrapper ul.k-reset.k-tabstrip-items.k-link {
  padding: 2px;
  margin-top: 5px;
  margin-bottom: 5px;
}
  .task_details_tabs.k-tabstrip-items-wrapper.k-reset.k-tabstrip-items.k-item.k-state-default {
    margin-bottom: 10px;
  }

.task_details_tabs .k-tabstrip-items-wrapper {
  padding: 0px 0px;
  margin-top: 5px;
}

  .task_details_tabs .k-tabstrip-items-wrapper ul li {
    padding: 0px 0px 0px 0px;
    margin: 0px 5px 0px 0px;
  }

kendo-tabstrip.main-tabs.task_details_tabs.k-tabstrip.k-floatwrap.k-tabstrip-top ul.k-reset.k-tabstrip-items {
  float: left !important;
  width: auto !important;
  padding-left: 1px;
}

kendo-tabstrip.main-tabs.task_details_tabs .k-tabstrip-items .k-item.k-state-active, .k-tabstrip-items .k-item.k-state-selected {
  border-bottom: 2px solid #007bff !important;
  color: #ffffff;
  background: #007bff !important;
  padding: 5px;
  border-radius: 5px 5px 0px 0px;
  margin-top: 0px;
}
.task_details_tabs {
  padding: 0px !important;
  background:none !important;
}
kendo-tabstrip.main-tabs.task_details_tabs .k-tabstrip-items.k-tabstrip-items .k-link {
  padding: 0px 10px !important;
  font-family: Poppins,'sans-serif' !important;
}
.table.project-details_page td, .table th {
    border:none !important;
}

.outofc_colp .col-md-12{
  padding: 0;
}
.dal_multiselect_33{
    display:flex;
    float:right !important;
}
.nav-body nav .collapse, .nav-body nav .collapsing {
  display: none;
 /* height: 0;*/
}


.nav-body.body-pd .header_toggle{
  margin-left: 12px;
  transition: all 0.5s ease-in-out;
}
.chat-Priority5149 {
  float: none !important;
}

.headding67 h5{margin-left:0 !important;}

.bypass_mtask18{
  height: calc(100vh - 280px);
  overflow: auto;
}

.bypass_frmview{
  height: calc(100vh - 280px);
  overflow: auto;
}

.bypass_mtask96{
  height: calc(100vh - 280px);
  overflow: auto;
}

/*==start chat message style==*/

.icon-user-solid-circle{
  font-size:20px;
  color:#a1a5b7;
}

.chat_username{
  color:#000;
  line-height: 28px;
  font-weight: 600;
}

.dal_btnback i {
  font-size: 15px;
  margin-left: 5px;
}

.chat_priority{
  color:#000;
  font-weight: 500;
  float: right;
  font-size: 10px;
}

.create_date{
  margin-left: 20px;
  font-size: 10px;
  color: #888;
  font-weight: 400;
}

/*==end chat message style==*/


.notification-msg .k-i-success{
  font-size: 17px;
  color: #155724;
}

.notification-msg .k-i-error{
  font-size: 17px;
  color: #721c24;
}

.notification-msg .k-i-warning{
  font-size: 17px;
  color: #856404;
}

.notification-msg .k-i-info{
  font-size: 17px;
  color:#0c5460;
}

.nav-body .left-project-div .report-show_hide_btn66 {
  border: none;
  padding: 5px 7px;
  background: #009ef7;
  color: #fff;
  transform: rotate(90deg);
  border-radius: 5px 5px 0px 0px;
  float: right;
  position: relative;
  margin: 300px -62px;
  z-index: 999;
  font-size: 1rem;
  font-weight: 500;
  letter-spacing:0.5px;
  overflow-x: overlay;

}

.reportCollapse .col-md-12.headding66 {
  display: none;
}

.reportCollapse {
  width: 0px !important;
  flex: 0;
  transition: 0.5s;
}

.report_gridwidth {
  float: left;
  flex: 0 0 99.81% !important;
  max-width: 99.81% !important;
}


.nav-body.body-pd .header_toggle {
  margin-left: 12px;
  transition: all 0.5s ease-in-out;
}

.table-bordered td, .table-bordered th {
  border: 1px solid #f2f2f2 !important;
  padding: 3px 10px;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0,0,0,.05);
}

.k-splitbar {
  background-color: #e1e1e1 !important;
  padding: 0px 0px !important;
  margin-right: 10px !important;
  margin-left: 10px !important;
}

.k-splitbar-draggable-horizontal .k-resize-handle {
  width: 3px !important;
  height: 35px !important;
  position: static;
  z-index: 1;
 
}


.new-tab16 {
  padding-top: 0px !important;
}

.splitter96 {
  height: 80vh !important;
  overflow: auto;
}

kendo-splitter-pane.splitter18.k-pane.k-scrollable {
  background: #f2f2f2;
  /* height: 85vh; */
  min-width:60%;
  width: 100%;
}

.k-splitter-flex .k-pane.k-state-hidden, .k-splitter-flex .k-pane[hidden], .k-splitter-flex .k-pane-flex.k-state-hidden, .k-splitter-flex .k-pane-flex[hidden] {
  min-width: 0% !important;
}

maindiv {
  margin: 0 auto;
  display: grid;
  grid-gap: 0rem;
  gap: 0rem;
}

@media (min-width: 480px) {
  maindiv {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media (min-width: 600px) {
  maindiv {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 900px) {
  maindiv {
    grid-template-columns: repeat(2, 1fr);
  }
}


@media (max-width: 1300px ) {
  .task-heading {
    font-size: 15px;
  }
}


.k-splitbar-horizontal .k-i-arrow-60-left {
  font-size: 25px !important;
  z-index: 999;
  display: flex;
  position: relative;
  top:-10px;
}

.k-splitbar-horizontal .k-i-arrow-60-right {
  font-size: 25px !important;

  padding: 5px;
  border-radius: 10px;
 
  z-index: 999;
  display: flex;
  position: fixed;
  margin-top: 130px;
}


.k-ghost-splitbar-horizontal, .k-splitbar-horizontal {
  width: 10px !important;
}
kendo-grid.nestedproject-info .k-grid-content.k-virtual-content {
  max-height: fit-content !important;
}
.k-grid-header .k-grid-header-sticky, .k-grid-header .k-grid-header-sticky.k-sorted {
 border-right-color: rgb(200 200 200 / 50%) !important;
 border-left-color: rgb(200 200 200 / 50%) !important;
}
.kendo-grid.nestedproject-info thead {
  display: none !important;
}

.taskino_height96 {
  /* height: 70vh; */
  height:calc(100vh - 235px);
  overflow: auto;
}
.taskino_height40 {
  height: 87vh;
  overflow: auto;

}
.file-collection-bg {
  background: #edeff7;
  padding:10px;
}
.file-collectionhover:hover {
  background: aliceblue;
  cursor: pointer;
}
.dal-listexpot9 {
  margin-top: 1px !important;
}
.dal-listexpot.right95 {
  margin-right: 5px !important;
}
.height-50vh{
    height:50vh !important;
    overflow:auto;

}
.action-btn-tasks1:hover {
  background: aliceblue;
  color: #1976d2;
  cursor: pointer !important;
}

.action-btn-tasks1 {
  background: none;
  color: #000000;
  font-family: Poppins,'sans-serif' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 10px;
  border-right: 1px solid #e9e9e9;
  border-radius: 0px;
  border-left: none;
  border-bottom: 0px;
  font-size: 12px;
  cursor: pointer !important;
}

.filter_dropdown{
  padding: 5px 10px 3px;
  text-align: center;
}

.filter_dropdown .k-button{
  padding:3px 8px;
  font-size: 0.85rem;
  font-weight: 500;
}
.k-splitter-flex .k-splitter-horizontal{
    height:100vh !important;
}


.right95{
  transform: translate(-122%, 10%);
    background-color: #e8fff3;
    color: #50cd89;
    padding: 10px 10px 10px 10px;
    line-height: 15px;
    box-shadow: none;
    border: 0;
    transition: all 0.2s ease-in-out;
}

.right95:hover {
  background: #50cd89;
  color: #FFFFFF;
}

.right33{
  transform: translate(-5px);
  background-color: #e8fff3;
  color: #50cd89;
  padding: 10px 10px 10px 10px;
  line-height: 15px;
  box-shadow: none;
  border: 0;
  transition: all 0.2s ease-in-out;
}

.right33:hover {
  background: #50cd89;
  color: #FFFFFF;
}

.headding66 .number_ofrecord {
  position: absolute;
  top: -50px;
  right: 5px;
}

.assignment_ngrid kendo-grid{
  max-height: calc(100vh - 195px);
  overflow: auto;
}

.audith_btn{
  transform: translate(15px);
  background-color: #e8fff3;
  color: #50cd89;
  padding: 11px 15px 11px 15px;
  line-height: 15px;
  box-shadow: none;
  border: 0;
  transition: all 0.2s ease-in-out;
}

.audith_btn:hover {
  background: #50cd89;
  color: #FFFFFF;
}


.setup_outoffice .k-window-content{padding: 0 !important;}

.setup_outoffice{
  height: calc(100vh - 165px);
  overflow: auto;
  padding: 15px;
}

.assign_user{

  max-height: calc(100vh - 190px);
}


.login_frm{
  display: flex;
    justify-content: center;
    align-items: center;
    width:330px;
    height: 90vh;
    margin: 0px auto;
    font-family: 'Roboto', Arial, sans-serif;
    flex-wrap: wrap;
    flex-direction: column;
}

.signin_txt{font-weight: 500 !important; text-decoration: underline;}

.dal_multiselect_44{
  float: right;
  max-height: calc(100vh - 190px);
}

.workflow_pname{
  float: left;
  margin-top: 3px;
}

.workflo_cbox{
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 108px;;
}

.pop_width29 .k-dialog {
  width: 400px !important;
}

.pop_width29 .col-md-6 {
  flex: 0 0 100%;
  max-width: 100%;
}

.pop_width206 .k-dialog {
  width: 400px !important;
}

.pop_width206 .col-md-6 {
  flex: 0 0 100%;
  max-width: 100%;
}

.pop_width98 .k-dialog {
  width: 400px !important;
}

.pop_width98 .col-md-6 {
  flex: 0 0 100%;
  max-width: 100%;
}