.m-0{
    margin:0px !important;
}
.p-0{
    padding:0px !important;
}
.m-top-0{
    margin-top:0px !important;
}
.m-top-5 {
  margin-top: 5px !important;
  
}
.m-top-10 {
  margin-top: 10px !important;
}
.m-top-15 {
  margin-top: 15px !important;
}
.m-top-20 {
  margin-top: 20px !important;
}
.m-bottom-0 {
  margin-bottom: 0px !important;
}
.m-bottom-5 {
  margin-bottom: 5px !important;
}

.m-bottom-10 {
  margin-bottom: 10px !important;
}

.m-bottom-15 {
  margin-bottom: 15px !important;
}

.m-bottom-20 {
  margin-bottom: 20px !important;
}
.m-right-0 {
  margin-right: 0px !important;
}

.m-right-5 {
  margin-right: 5px !important;
}

.m-right-10 {
  margin-right: 10px !important;
}

.m-right-15 {
  margin-right: 15px !important;
}

.m-right-20 {
  margin-right: 20px !important;
}

.m-left-0 {
  margin-left: 0px !important;
}

.m-left-5 {
  margin-left: 5px;
}

.m-left-10 {
  margin-left: 10px !important;
}

.m-left-15 {
  margin-left: 15px !important;
}

.m-left-20 {
  margin-left: 20px !important;
}

.p-top-0{
    padding-top:0px !important;
}
.p-top-5 {
  padding-top: 5px !important;
}
.p-top-10 {
  padding-top: 10px !important;
}

.p-top-15 {
  padding-top: 15px !important;
}
.p-top-20 {
  padding-top: 20px !important;
}
.p-right-0 {
  padding-right: 0px !important;
}

.p-right-5 {
  padding-right: 5px !important;
}

.p-right-10 {
  padding-right: 10px !important;
}

.p-right-15 {
  padding-right: 15px !important;
}

.p-right-20 {
  padding-right: 20px !important;
}

.p-bottom-0 {
  padding-bottom: 0px !important;
}

.p-bottom-5 {
  padding-bottom: 5px !important;
}

.p-bottom-10 {
  padding-bottom: 10px !important;
}

.p-bottom-15 {
  padding-bottom: 15px !important;
}

.p-bottom-20 {
  padding-bottom: 20px !important;
}

.p-left-0 {
  padding-left: 0px !important;
}

.p-left-5 {
  padding-left: 5px !important;
}

.p-left-10 {
  padding-left: 10px !important;
}

.p-left-15 {
  padding-left: 15px !important;
}

.p-left-20 {
  padding-left: 20px !important;
}
.padding-20 {
  padding: 20px !important;
}
.p-lr-10{
    padding-left:10px;
    padding-right:10px;
}
.border-ccc{
    border:1px solid #ccc;

}
.border-eee {
  border: 1px solid #eee;
}
.border-f2f2f2 {
  border: 1px solid #f2f2f2;
}
.height-70vh {
  max-height: 70vh !important;
  overflow: auto;
  overflow-x: hidden;
}

.height-64vh {
  max-height: 64vh !important;
  overflow: auto;
  overflow-x: hidden;
}


.height-60vh {
  max-height: 60vh;
  overflow: auto;
}
.border-right-eee{
    border-right:1px solid #eee;
}
.p-10{
    padding:10px;
}
.display-block{
    display:block;
}
.p-top-1{
    padding-top:1px;
}
.height-60vh{
    height:60vh;
    overflow:auto;
}
.padding-5{
    padding:5px
}
/*.due-red {
  color: #f1416c !important;
  opacity: 0.8 !important;
}*/
.dashed-border-bottom-f2f2f2{
    border-bottom:1px dashed #f2f2f2;
}
.dashed-border-bottom-f2f2f2 a{
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block;
}

.border-f2f2f2 {
  border: 1px solid #f2f2f2;
}


.create_editproj{
  height: calc(100vh - 215px);
  overflow: auto;
  overflow-x: hidden;
}

.secondary-btn {
  display: flex;
    border: #F1F4FF;
    color: #009ef7;
    height: 35px;
    padding: 10px;
    background: #F1F4FF;
    z-index: 8;
    cursor: pointer;
    float: left;
}
.secondary-btn:hover {
  background: #009ef7;
  color: #fff;
}

.reassign_userdiv .secondary-btn {
  display: flex;
  border: 0;
  color: #fff;
  padding: 4px 11px 5px;
  background: #009ef7;;
  z-index: 8;
  height: auto;
  cursor: pointer;
  float: left;
}
.reassign_userdiv .secondary-btn:hover {
  background: #2884EF;
}


.text-center {
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
